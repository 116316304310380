<template>
  <v-card>
    <v-card-title>
      WhiteLabel
      <div class="text-center">
        <v-btn
          color="primary"
          class="ma-2"
          elevation="5"
          dark 
          @click="showAddModal">
          Add WhiteLabel entry
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <!-- Search field -->
      <v-text-field
        v-model="phoneSearch"
        append-icon="icon-search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <!--The WhiteLabel datatables-->
    <v-data-table
      :headers="whitelableHeaders"
      :items="whitelableEntries"
      class="elevation-1"
      :search="phoneSearch"
    >
      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr v-for="(item,idx) in items" :key="idx">
            <td v-for="(header,key) in headers" :key="key">

               <!-- Contact div -->
               <div v-if="header.value == 'Contact'">
                <v-edit-dialog
                :return-value.sync="item[header.value]"
                large
                @save="save(item)"
                @cancel="cancel"
              > {{item[header.value]}}
                <template v-slot:input>
                  <v-text-field
                    :value="item[header.value]"
                    @change="newValue => item[header.value] = newValue"
                    label="Edit"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
              </div>
              <!-- Phone div -->
              <div v-if="header.value == 'Phone'">
                <v-edit-dialog
                :return-value.sync="item[header.value]"
                large
                @save="save(item)"
                @cancel="cancel"
              > {{item[header.value]}}
                <template v-slot:input>
                  <v-text-field
                    v-model="item[header.value]"
                    label="Edit"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
              </div>
              
              <!-- Group div -->
              <div v-if="header.value == 'Group'">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  large
                  @save="save(item)"
                  @cancel="cancel"
                  > {{item[header.value]}}
                  <template v-slot:input>
                    <v-select
                      v-model="item[header.value]"
                      :items="MemberOfGroups"
                    ></v-select>
                  </template>
                </v-edit-dialog>
              </div>

              <!-- Default div-->
              <div v-if="header.value == 'Default'">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  large
                  @save="save(item)"
                  @cancel="cancel"
                  > {{item[header.value]}}
                  <template v-slot:input>
                    <v-checkbox
                      v-model="item[header.value]"
                      :on-icon="'icon-check_box'"
                      :off-icon="'icon-check_box_outline_blank'"
                      :true-value="true"
                      :false-value="false"                 
                      :label="`${header.text}: ${item[header.value]}`"
                    ></v-checkbox>
                  </template>
                </v-edit-dialog>
              </div>


              <!-- action div-->
              <div v-if="header.value == 'Action'">
                  <v-icon
                  small
                  @click="deleteRow(item)"
                  >
                  icon-delete
                </v-icon>
              </div>

              <!-- The rest of the headers -->
              <div v-if="header.value != 'Contact' && header.value != 'Phone' && header.value != 'Group' && header.value != 'Action' && header.value != 'Default'">
                <v-edit-dialog
                  :return-value.sync="item['Default_Queue']"
                  large
                  @save="save(item)"
                  @cancel="cancel"
                > {{item[['Default_Queue']]}}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item['Default_Queue']"
                      label="aa"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="icon-warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </template>
    </v-data-table>

    <v-dialog 
    v-model="isAddModalActive"
    width="550"
    >
    <AddWhitelableEntry @close="closeAddModal" @saved="loadDataWithEvent"/>
  </v-dialog>

  </v-card>
 
</template>

<script>
import AddWhitelableEntry from "./AddWhitelableEntry";
// import MakeCall from './MakeCall.vue';

export default {
    name: "Whitelabel",
    components: { 
      AddWhitelableEntry//,
      // MakeCall
    },
    created() {
      
        this.NotificationsApi = new window.platformClient.NotificationsApi();
        this.ArchitectApi = new window.platformClient.ArchitectApi();
        this.ConversationsApi = new window.platformClient.ConversationsApi();
        this.GroupsApi = new window.platformClient.GroupsApi();
   
        this.loadData(); 
       
    },
    methods: {
      sleep: function (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
},
    
        loadData: function () {
            if (this.$root.$data.sharedState.whiteLableIds != undefined) {
                this.headers = [];
                this.whitelableEntries = [];
                this.MemberOfGroups = this.$root.$data.sharedState.MemberOfGroups;
            //    this.$root.$data.sharedState.whiteLableIds.map(whiteLable => this.combineDataTables(whiteLable.id));
             this.combineDataTables();
              }
        },
        loadDataWithEvent: function(){
          this.loadData();
          this.$emit('event_whitelableModified');
        },
        closeAddModal: function(){
          this.isAddModalActive =false;
        },
        showAddModal: function(){
          this.isAddModalActive = true;
        },
        deleteRow: function (row){
          var authToken = window.client.authData.accessToken;
           
              let opts = {
                  "Token" : authToken
                };
         
          fetch(process.env.VUE_APP_API_BASE_URI + "/WhiteLabel/DeletePhone/" + row.key, {
                      method: 'DELETE',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("deleteFlowsDatatableRow returned successfully.");
                          this.loadDataWithEvent();
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                        
                        console.log(data)
                        })                                                
                  
                  .catch((err) => {
                  console.log("There was a failure calling deleteFlowsDatatableRow");
                  console.error(err);
              });
         
        },
        getDataTableSchema: function (tableId) {
          tableId="a3a257ca-7356-4787-8774-ee5825f304d0";
            let opts = {
                "Expand": "schema" ,// String | Expand instructions for the result
                "Id":tableId
            };
            var self = this;
            fetch(process.env.VUE_APP_API_BASE_URI + "/Datatables/Schema", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      })
                .then((data) => {
                var headers = [];
                //Save the phonebook schema (is consistent accros phonebooks)
                self.$root.$data.sharedState.whitelableSchema = data;
                

                for (var key of Object.keys(data.schema.properties)) {
                    if (key != "key") {
                        headers.push({ text: data.schema.properties[key].title, value: key, type: data.schema.properties[key].type });
                    }
                }
                this.combineHeaders(headers);
            })
                .catch((err) => {
                console.log("There was a failure calling getFlowsDatatablesDivisionview");
                console.error(err);
            });
        },
        combineDataTables: async function () {
          var tableId = "a3a257ca-7356-4787-8774-ee5825f304d0";
          if (tableId) {
            console.log("Combining data from whitelable. Current table: '" +tableId +"'");
              
            var authToken = window.client.authData.accessToken;

            var gotAllData = false;
            do {
              let opts = {
                  "Token" : authToken
                };
                var self = this;
                try {
                    console.log(`Reading all whitelabes from ${tableId}, with options`);
                 
                    self.getDataTableSchema(tableId);
                    gotAllData = await new Promise(resolved => {
                    var loadedAllData = false;
                    fetch(process.env.VUE_APP_API_BASE_URI + "/WhiteLabel/phones", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                                           
                        self.whitelableEntries = data.map(x => this.addTableId(x, tableId)).filter(ent => self.$root.$data.sharedState.MemberOfGroups.includes(ent.Group))
                            .sort(function (a, b) {
                            return a.Contact > b.Contact ? 1 : -1;
                        });

                  //      self.whitelableEntries.map(x => x.Default_Queue =  (window.default_queue_list.find(y => y.Id ===x.Default_Queue).Name));
                       
                            loadedAllData = true;
                        
                        resolved(loadedAllData);
                    })
                        .catch((err) => {
                        console.log("There was a failure calling getFlowsDatatableRows");
                        console.error(err);
                    });
                  });
                }
                catch (err) {
                    console.log(err);
                }
              
            }while(!gotAllData);

          }
        },
        addTableId: function (json, id) {
            json.tableId = id;
            return json;
        },
        combineHeaders: function (newHeaders) {
            var c = this.whitelableHeaders.concat(newHeaders);
            this.whitelableHeaders = c.filter((item, pos) => c.map(i => i.value).indexOf(item.value) === pos);
            //If we havn't already appended an action column do it
            if(this.whitelableHeaders.filter(e => e.text === "Actions").length == 0){
              this.whitelableHeaders.push({"text":"Actions","value":"Action","type":"actions","sortable":false});
            }
            
          },
        updateDataTableRow: function (row) {

          var authToken = window.client.authData.accessToken;

          var opts = { ...row,
                            "Auth": {
                            "Token":authToken
                            } 
                     } 
          ;
          var tableId = opts.tableId;
          console.log(opts);
          if (tableId) {
              // Remove the id from the body, since this is not part of the actual table
              delete opts.tableId;
              opts.Default= opts.Default.toString();
        
              console.log(opts);
           
              // Now update the row, with the new key as well
              fetch(process.env.VUE_APP_API_BASE_URI + "/WhiteLabel/EditPhone", {
                      method: 'PUT',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                        
                        console.log(data)
                        })                                                
                  
                  .catch((err) => {
                  console.log("There was a failure calling putFlowsDatatableRow");
                  console.error(err);
              });
          }
          
        },
        save(item) {
          console.log(item);
          console.log("Dialog save");
          this.updateDataTableRow(item);
        },
        cancel() {
          console.log("Dialog cancel");
        },
    },
    data() {
        return {
            ArchitectApi: {},
            ConversationsApi: {},
            NotificationsApi: {},
            GroupsApi: {},
            connection: null,
            edit: false,
            connectUri: "",
            channelId: "",
            whitelableHeaders: [],
            whitelableEntries: [],
            MemberOfGroups: [],
            phoneSearch: "",
            conversationId: "",
            userParticipantId: "",
            externalParticipantId: "",
            isAddModalActive: false,
            isMakeCallModalActive : false,
            contact : "",
            phone:""
        };
    }
}
</script>

<style lang="scss">
  h1 {
    color: var(--v-secondary);
  }

  div.view-selection > h1, div.view-selection > button {
    float: left;
  }

  div.view-selection > button {
    margin-top: 5px;
    margin-left: 16px;
  }
  
  div.clear-both {
    clear: both; 
  }
  
  td > div > button {
    margin-left: 10px;
    padding-bottom: 2px;
  }
</style>