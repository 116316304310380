<template>
  <v-card>
    <v-card-title>
      Function selector
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="icon-search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="entries"
      class="elevation-1"
      :search="search"
    >

      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr v-for="(item,idx) in items" :key="idx">
            <td v-for="(header,key) in headers" :key="key">
              <v-checkbox
                v-if="header.value === 'key'"
                v-model="item.isMember"
                :label="`${item.isMember ? 'Active' : 'Inactive'}`"
                :on-icon="'icon-toggle_on'"
                :off-icon="'icon-toggle_off'"
                @click="changeFunctionState(item)"
              ></v-checkbox>
              <div v-if="header.value !== 'key'">
              {{item[header.value]}}
            </div>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="icon-warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'Functions',

  data () {
    return {
      ArchitectApi: {},
      RoutingApi: {},
      headers: [{text: "Name", value: "Name"}, {text: "Division", value: "Division"}, {text: "Activate", value: "key"}],
      entries: [],
      search: '',
      userQueues: [],
      userSkills: [],
      functionTableId: "3d1a7ccd-110c-4759-992c-506ff668fb75"
    }
  },

  mounted: function () {
    this.ArchitectApi = new window.platformClient.ArchitectApi();
    this.RoutingApi = new window.platformClient.RoutingApi();
    this.getFunctionEntries();
  },

  methods: {
    loadData: function () {
      this.getDataTablePage()
    },
    getFunctionEntries: function () {
            let opts = {
                "Expand": "schema" ,// String | Expand instructions for the result
                "Id":this.functionTableId
            };
         
            fetch(process.env.VUE_APP_API_BASE_URI + "/Datatables/Schema", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      })
        .then((data) => {
          // var headers = []
          
          // for (var key of Object.keys(data.entities[0].schema.properties)) {
          //   headers.push({ text: data.entities[0].schema.properties[key].title, value: key })
          // }

          // this.headers = headers
          this.getDataTablePage(data.id, 1)
        })
        .catch((err) => {
          console.log('There was a failure calling getFlowsDatatables');
          console.error(err);
        });
    },
    async getDataTablePage() {//tableId, page
      var authToken = window.client.authData.accessToken;
      let opts = {
                  "Token" : authToken
                };
      var self = this;

      fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/GetFunctions", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) 
            .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      })
           .then(data => {
            console.log(data.entities[0]);
            self.filterAndJoinAvailableFunctions(data.entities)
           
            self.updateFunctionStates()
        })
        .catch((err) => {
          console.log('There was a failure calling getFlowsDatatableRows');
          console.error(err);
        });
    },
    filterAndJoinAvailableFunctions(entities) {
      var functions = entities;
      if (!this.$root.$data.sharedState.allDivsPermitted) {
        functions = entities.filter((f) => this.$root.$data.sharedState.divisionsPermitted.indexOf(f.Division) !== -1) // this is filtering everything out        
      }

     functions.forEach((f) => {
        f.Queues = f.Queues.split(";");
        f.isMember = false;
      });
      this.entries = functions.sort(
        function(a, b) {          
            if (a.Division === b.Division) {
              // Name is only important when Divisions are the same
              return a.Name > b.Name ? 1 : -1;
            }
            return a.Division > b.Division ? 1 : -1;
        });
    },
    async updateFunctionStates() {
        try {
          const opts = {pageNumber: 1, pageSize: 100, joined: true, sortOrder: "asc"};
          const userId = this.$root.$data.sharedState.user.id;

          await this.RoutingApi.getUserRoutingskills(userId, opts).then(data => {
            this.userSkills = data.entities
          });

          await this.RoutingApi.getRoutingQueuesMe(opts).then(data => {
            this.userQueues = data.entities
          });

          this.entries.forEach((func) => {
            let skillFound = true;
            let queueFound = true;

            func.Queues.forEach(sQueueId => {
              queueFound = queueFound && this.userQueues.some((queue) => {
                return queue.id === sQueueId;
              })
            })

            if(!Array.isArray(func.Skill)) {
              func.Skill=func.Skill.split(";");}

            func.Skill.forEach(skillId => {
              skillFound = skillFound && this.userSkills.some((skill) => {
                return skill.id === skillId;
              })
            })

            func.isMember = queueFound && skillFound;

          })
        } catch (error) {
          console.log('There was a failure calling getUserRoutingskills');
          console.error(error);
        }

    },

    async GetUserQueueEntities() {
      let opts = {
        'pageNumber': 1, // Number | Page number
        'pageSize': 500, // Number | Page size
      };

      const usuerQueueEntities = await this.RoutingApi.getRoutingQueuesMe(opts);

      return usuerQueueEntities.entities;
    },

    async changeFunctionState(item) {
      try {
        if(item.isMember){
          await this.getQueuesForUser(item,true);
          await this.addSkillsToUser(item);
        } else {
          await this.getQueuesForUser(item,false);
          await this.deleteMultipleSkillsToUser(item);
        }
        await this.updateFunctionStates();
      } catch (error) {
        console.log('There was a failure calling update Function States');
        console.error(error);
      }
    },
    async getQueuesForUser(item,activate){
      const queueEntities = await this.GetUserQueueEntities();

      var queuesToAdd= [];
      for (const queueId of item.Queues) {
        if(queueEntities.filter(x => x.id === queueId).length>0){
          await this.activateQueueForUser(queueId,activate)
        }else {
          queuesToAdd.push(queueId);
        }
      }

      if(activate && queuesToAdd.length>0){
        await this.addQueueToUser(item, queuesToAdd);
      }


    },
    async activateQueueForUser(queueId, activate){
       
      let userId = this.$root.$data.sharedState.user.id; // String | User ID
      let body = {
        "joined": activate
       }; // Object | Queue Member

      await this.RoutingApi.patchUserQueue(queueId, userId, body)
        .then((data) => {
          console.log(`patchUserQueue success! data: ${JSON.stringify(data, null, 2)}`);
        })
        .catch((err) => {
          console.log('There was a failure calling patchUserQueue');
          console.error(err);
        });
    },
    async addQueueToUser(item, queuesToAdd) {
      let body = [{"id": this.$root.$data.sharedState.user.id}]; // Object | Queue Members
      let opts = { 
        '_delete':false // Boolean | True to delete queue members
      };

      let i = 0
      let statusChangedForQueues = []

      for (const queueId of queuesToAdd) {
        if (queueId !== "") {
          await this.RoutingApi.postRoutingQueueMembers(queueId, body, opts)
              .then(() => {
                ++i
                statusChangedForQueues.push(queueId)

                if (i === queuesToAdd.length){
                  this.functionStateChanged(item, statusChangedForQueues.length === queuesToAdd.length, item.isMember)
                }
              })
              .catch((err) => {
                console.log('There was a failure calling postRoutingQueueMembers');
                console.error(err);
              });
        }
      }
    },
    async deleteSkillsToUser(skillId) {
      let userId = this.$root.$data.sharedState.user.id;     

      await this.RoutingApi.deleteUserRoutingskill(userId, skillId)
        .then(() => {
          console.log('deleteUserRoutingskill returned successfully.');
        })
        .catch((err) => {
          console.log('There was a failure calling deleteUserRoutingskill');
          console.error(err);
        });
    },
    async deleteMultipleSkillsToUser(item) {
      if(!Array.isArray(item.Skill)) {
        item.Skill=item.Skill.split(";");}

      for (const skillId of item.Skill) {
        setTimeout(() => { console.log("waiting"); }, 800);
        await this.deleteSkillsToUser(skillId);
      }
    },
    async addSkillsToUser(item) {

      var userId= this.$root.$data.sharedState.user.id;
      var skillsBody = [] ;       
       
     if(!Array.isArray(item.Skill)) {
      item.Skill=item.Skill.split(";");
    }
     try {
       item.Skill.forEach(skillId => {
         skillsBody.push({
           "id":skillId,
           "proficiency": 1
         })

       });


       await this.RoutingApi.patchUserRoutingskillsBulk(userId, skillsBody)
           .then(() => {
             console.log("Successfully Patched Skills!");
           })
     } catch (error) {
       console.log('There was a failure calling patchUserRoutingskillsBulk to add skill');
       console.error(error);
     }
    
    },
    functionStateChanged: function (item, changedAllQueueStatus, state) {
      item.isMember = changedAllQueueStatus ? state : !state;
    },
  },
}




</script>

<style lang="scss">
  h1 {
    color: var(--v-secondary);
  }

  div.view-selection > h1, div.view-selection > button {
    float: left;
  }

  div.view-selection > button {
    margin-top: 5px;
    margin-left: 16px;
  }
  
  div.clear-both {
    clear: both; 
  }
  
  td > div > button {
    margin-left: 10px;
    padding-bottom: 2px;
  }
  div.v-input__control > div.v-input__slot > div.v-input--selection-controls__input > .v-icon {
    font-size: 32px;
  }
  // .v-sheet.v-card:not(.v-sheet--outlined) {
  //   box-shadow: none;
  // }
</style>