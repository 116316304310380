import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

// Language support
import i18n from './plugins/i18n'

// Icons
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false

var store = {
  debug: true,
  state: {
    token: '',
    user: {},
    isAdmin: false,
    isDeveloper: false,
    isWhitelableEditor:false,
    isPhonebookEditor:false
  },
  setTokenAction (newValue) {
    if (this.debug) console.log('setToken triggered with', newValue)
    this.state.token = newValue
  },
  clearTokenAction () {
    if (this.debug) console.log('clearToken triggered')
    this.state.token = ''
  }
}

new Vue({
  vuetify,
  i18n,
  data() {
    return {
      privateState: {},
      sharedState: store.state
    }
  },
  render: h => h(App)
}).$mount('#app')
