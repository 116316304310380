<template>
  <v-card>
    <v-card-title>
      Function editor
      <v-spacer></v-spacer>
      <v-text-field
        v-if="entities"
        v-model="search"
        append-icon="icon-search"
        label="Search"
        class="search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-dialog v-if="entities" v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2" v-on="on">Add new function</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Add function</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 v-for="(header,key) in headers" :key="key">

                <v-overflow-btn
                    v-if="header.value === 'Division'"
                    :items="divisions"
                    label="Select division"
                    hide-details
                    v-model="newItem[header.value]"
                    editable
                ></v-overflow-btn>

                <v-text-field v-if="header.type === 'string' && header.value !== 'Skill' && header.value !== 'Division'" v-model="newItem[header.value]" :label="header.text"></v-text-field>
                <v-text-field v-if="header.type === 'integer'" v-model.number="newItem[header.value]" type="integer" :label="header.text"></v-text-field>
                <v-checkbox v-if="header.type === 'boolean'" v-model="newItem[header.value]" :label="`${header.text}: ${newItem[header.value]}`"></v-checkbox>
              </v-flex>
              <QueueEditor v-if="queues" :table="table" :func="newItem" :queues="queues" :editingItem="false"></QueueEditor>
              <SkillEditor v-if="skills" :table="table" :func="newItem" :skills="skills"   :editingItem="false"></SkillEditor>
            </v-layout>
          </v-container>
        </v-card-text>
      
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" text @click="closeNew">Cancel</v-btn>
          <v-btn color="primary" dark class="mb-2" text @click="saveNew">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-data-table
      :headers="headers"
      :items="entities"
      class="elevation-1"
      :search="search"
      v-if="entities"
    >
      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr v-for="(item,idx) in items" :key="idx">
            <td v-for="(header,key) in headers" :key="key">
              <div v-if="header.value === 'key'">
                {{item[header.value]}}
              </div>
              <div v-if="header.value !== 'key' && header.type === 'string' && header.value !== 'Skill' && header.value !== 'Division'">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  @save="save(item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                > {{item[header.value]}}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item[header.value]"
                      label="Edit"
                      single-line
                    ></v-text-field>
                    
                  </template>
                </v-edit-dialog>
              </div>

              <div v-if="header.value === 'Division'">
                <v-select
                    :items="divisions"
                    @change="save(item)"
                    hide-details
                    v-model="item[header.value]"
                    editable
                ></v-select>
              </div>

              <div v-if="header.type === 'Queues'">
                <v-edit-dialog
                  @save="save(item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                > {{item[header.value]}}
                  <template v-slot:input>
                    <QueueEditor :table="table" :func="item" :queues="queues" :editingItem="true" ></QueueEditor>
                  </template>
                </v-edit-dialog>
              </div>

              <div v-if="header.value === 'Skill'">
                <v-edit-dialog
                  @save="save(item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                > {{showSkillText(item,header.value)}}
                  <template v-slot:input>
                    <SkillEditor :table="table" :func="item" :skills="skills" :editingItem="true"></SkillEditor>
                  </template>
                </v-edit-dialog>
              </div>

              <div v-if="header.type === 'integer'">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  @save="save(item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                > {{item[header.value]}}
                  <template v-slot:input>
                    <v-text-field
                      v-model.number="item[header.value]"
                      type="number"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-if="header.type === 'boolean'">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  large
                  @save="save(item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                > {{item[header.value]}}
                  <template v-slot:input>
                    <v-checkbox
                      v-model="item[header.value]"
                      :label="`${header.text}: ${item[header.value].toString()}`"
                    ></v-checkbox>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-if="header.type === 'actions'">
                <v-icon
                  small
                  @click="deleteDataTableRow(item)"
                >
                  icon-delete
                </v-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="icon-warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import QueueEditor from './QueueEditor'
import SkillEditor from './SkillEditor'


export default {
  name: 'FunctionsEditor',

  data () {
    return {
      ArchitectApi: {},
      RoutingApi: {},
      ObjectsApi: {},
      table: {},
      tables: [],
      search: '',
      headers: [],
      entities: [],
      queue: {},
      queues: [],
      queueheaders: [{text: "Name", value: "name"}, {text: "Actions", value: "id"}],
      skill: {},
      skills: [],
      skillheaders: [{text: "Name", value: "name"}, {text: "Actions", value: "id"}],
      divisions: [],
      defaultItem: {},
      newItem: {},
      dialog: false
    }
  },

  components: {
    QueueEditor,
    SkillEditor
  },

  mounted: function () {
    this.ArchitectApi = new window.platformClient.ArchitectApi();
    this.RoutingApi = new window.platformClient.RoutingApi();
    this.ObjectsApi = new window.platformClient.ObjectsApi();
    this.getFunctionsDataTable();
  },

  methods: {  closeDialog: function(){
        console.log('close dialog 1');
      
        this.$parent. $emit("close");   
      },
    loadData: function () {
           this.getDataTablePage();
        },

  showSkillText: function (item,header){
if(Array.isArray( item[header])) {
  var skillText = "";
    item[header].forEach(function(skill) {
      skillText = skillText + skill.name  + ", "
    // code
});

return skillText.slice(0, -2);
}
    
  },
    getFunctionsDataTable: function () {
      let opts = { 
        'expand': "schema", // String | Expand instructions for the result
        'pageNumber': 1, // Number | Page number
        'pageSize': 25, // Number | Page size
        'sortBy': "id", // String | Sort by
        'sortOrder': "ascending", // String | Sort order
        //'divisionId': ["divisionId_example"], // [String] | division ID(s)
        'name': "Functions" // String | Name to filter by
      };

      this.ArchitectApi.getFlowsDatatables(opts)
        .then((data) => {
          var headers = []
          
          for (var key of Object.keys(data.entities[0].schema.properties)) {
            var header = null
            if (key === 'Queues') {
              header = { text: data.entities[0].schema.properties[key].title, value: 'QueueText', type: 'Queues' }
            } else if (key !== 'key'){
              header = { text: data.entities[0].schema.properties[key].title, value: key, type: data.entities[0].schema.properties[key].type }
            }

            if (header) {
              headers.push(header)
              if (header.type === 'Queues') {
                this.defaultItem['Queues'] = [];
              } else {
                this.defaultItem[header.value] = header.type === 'boolean' ? false : header.type === 'string' ? '' : 0;
              }
            }
          }

          this.resetNew();

          headers.push({ text: 'Actions', type: 'actions' })

          this.headers = headers
          this.table = data.entities[0].id
          this.getDataTablePage(data.entities[0].id, 1)
        })
        .catch((err) => {
          console.log('There was a failure calling getFlowsDatatables');
          console.error(err);
        });
    },
    getDataTablePage: function () { // 
      var authToken = window.client.authData.accessToken;
      let opts = {
                  "Token" : authToken
                };
      var self = this;

      fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/GetFunctions", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) 
            .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      })
           .then(data => {
          self.filterAndJoinAvailableFunctions(data.entities)
       
          self.getQueues();
          self.getSkills();
          this.divisions = this.$root.$data.sharedState.divisionsPermitted;
        
        })
        .catch((err) => {
          console.log('There was a failure calling getFlowsDatatableRows');
          console.error(err);
        });
    },
    filterAndJoinAvailableFunctions: function (pageEntities) {
      var functions = pageEntities.filter((f) => this.$root.$data.sharedState.divisionsPermitted.indexOf(f.Division) !== -1)

      functions.forEach((f) => {
        f.Queues = f.Queues.split(";");
        for (var i = f.Queues.length - 1; i >= 0; --i) {
          if (f.Queues[i] === "") {
              f.Queues.splice(i,1);
          }
        }
        f.QueueText = "fetching data...";
        f.isMember = false;
      });
      this.entities = this.entities.concat(functions).sort(
        function(a, b) {          
            if (a.Division === b.Division) {
              // Name is only important when Divisions are the same
              return a.Name > b.Name ? 1 : -1;
            }
            return a.Division > b.Division ? 1 : -1;
        });
    },
    getSkills:  function(){
     console.log("getting skills function editor");
     var authToken = window.client.authData.accessToken;
           var opts = {     
             
                            "Token":authToken
                         
           };
            fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/GetSkills", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                            var skills = []

                            data.forEach(q => {
                              skills.push({ text: q.Name, value: q.Id });
                            });

                            this.skills = skills

                          this.entities.forEach((f) => {
                              this.setSkillText(f);
                               })
                                         
                        })  
               .catch(err => {
                console.log("There was a failure calling getSkills");
                  console.error(err);
                  return null;
               });
   },
    getDivisions: function() {
      let opts = {
        'pageNumber': 1, // Number | Page number
        'pageSize': 500, // Number | Page size
        'sortOrder': "asc", // String | Note: results are sorted by name.
        // 'name': "name_example", // String | Filter by queue name
        //'id': [...new Set(entities.map(f => f.key))], // [String] | Filter by queue ID(s)
        // 'divisionId': ["divisionId_example"] // [String] | Filter by queue division ID(s)
      };

      this.ObjectsApi.getAuthorizationDivisions(opts)
          .then((data) => {
            var divs = []

            console.log("Getting dem good Divisions")
            console.log(data.toString())

            data.entities.forEach(q => {
              console.log("Divisiony doodad")
              console.log(q.toString())
              // divs.push({ text: q.name, value: q.id });
              divs.push(q.name);
            });

            // this.divisions = divs
            this.divisions = this.$root.$data.sharedState.divisionsPermitted;
          })
          .catch((err) => {
            console.log('There was a failure calling getAuthorizationDivisions');
            console.error(err);
          });

    },
    getQueues: function () {
      let opts = { 
        'pageNumber': 1, // Number | Page number
        'pageSize': 500, // Number | Page size
        'sortOrder': "asc", // String | Note: results are sorted by name.
        // 'name': "name_example", // String | Filter by queue name
        //'id': [...new Set(entities.map(f => f.key))], // [String] | Filter by queue ID(s)
        // 'divisionId': ["divisionId_example"] // [String] | Filter by queue division ID(s)
      };

      this.RoutingApi.getRoutingQueues(opts)
        .then((data) => {
          var queues = []

          data.entities.forEach(q => {
            queues.push({ text: q.name, value: q.id });
          });

          this.queues = queues

          this.entities.forEach((f) => {
            this.setQueueText(f);
          })
        })
        .catch((err) => {
          console.log('There was a failure calling getRoutingQueues');
          console.error(err);
        });
    },
    setQueueText: function (func) {
      for (let i = 0; i < func.Queues.length; i++) {
        var queue = this.queues.find(q => q.value === func.Queues[i]);
        func.Queues[i] = { id: func.Queues[i], name: queue ? queue.text : "" };
      }

      this.sortFunctionQueues(func);
    },
    setSkillText: function (func) {
    if( ! Array.isArray( func.Skill)) {
      func.Skill =  func.Skill.split(";");
    }
      for (let i = 0; i < func.Skill.length; i++) {
        var skill = this.skills.find(q => q.value === func.Skill[i]);
        func.Skill[i] = { id: func.Skill[i], name: skill ? skill.text : "" };
      }

      this.sortFunctionSkills(func);
    },
    sortFunctionQueues: function (func) {
      if (func.Queues.length > 0) {
        func.Queues = func.Queues.sort(
          function(a, b) {          
              return a.name > b.name ? 1 : -1;
          });
        func.QueueText = func.Queues.map(q => q.name).join(", ");

       
      } else {
        func.QueueText = "";
      }
    },
    sortFunctionSkills: function (func) {
      if (func.Skill.length > 0) {
        func.Skill = func.Skill.sort(
          function(a, b) {          
              return a.name > b.name ? 1 : -1;
          });
        func.SkillText = func.Skill.map(q => q.name).join(", ");
     
      } else {
        func.SkillText = "";
      }
    },
    save (item) {
      console.log('Dialog save')
      console.log(item)
      this.updateDataTableRow(item);
      // this.snack = true
      // this.snackColor = 'success'
      // this.snackText = 'Data saved'
    },
    cancel () {
      console.log('Dialog cancel')
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled';
      
      
    },
    open () {
      console.log('Dialog open')
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close () {
      console.log('Dialog closed')
    },
    saveNew () {
      this.newItem.key = `${this.newItem.Name}_${this.newItem.Division}`
      this.entities.push(this.newItem)
      this.addDataTableRow(this.newItem)
      this.closeNew()
    },
    closeNew () {
      this.dialog = false
      setTimeout(() => {
        this.resetNew()
      }, 300)
    },
    resetNew () {
      this.newItem = Object.assign({}, this.defaultItem)
    },
    addDataTableRow: function (row) {
      var body = this.formatDataTableRow(row);
      var authToken = window.client.authData.accessToken;
      body.Auth ={
                            "Token":authToken
                            } ;
      
      fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/AddFunction", {
                      method: 'POST',
                      body:JSON.stringify(body), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                        console.log(`postFlowsDatatableRows success! data: ${JSON.stringify(data, null, 2)}`);
                       
                        })  
        .catch((err) => {
          console.log('There was a failure calling postFlowsDatatableRows');
          console.error(err);
        });
    },
    formatDataTableRow: function (row) {
      var body = { ...row };
      delete body.QueueText;
      delete body.isMember;
      var queues = [...new Set(body.Queues.map(q => q.id))];
      body.Queues = queues.join(";");
      var skills = [...new Set(body.Skill.map(q => q.id))];
      body.Skill = skills.join(";");
      return body;
    },
    updateDataTableRow: function (row) {
      var body = this.formatDataTableRow(row);
      var authToken = window.client.authData.accessToken;
      body.Auth ={
                            "Token":authToken
                            } ;

                            fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/EditFunction", {
                      method: 'PUT',
                      body:JSON.stringify(body), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                        
                        console.log(data)
                        })              
        .catch((err) => {
          console.log('There was a failure calling putFlowsDatatableRow');
          console.error(err);
        });
    },
    deleteDataTableRow: function (row) {
      const index = this.entities.indexOf(row)
      if (confirm('Are you sure you want to delete this row?')){
          var authToken = window.client.authData.accessToken;
           
              let opts = {
                  "Token" : authToken
                };
         
          fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/DeleteFunction/" + row.key, {
                      method: 'DELETE',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("deleteFlowsDatatableRow returned successfully.");
                    
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                        this.entities.splice(index, 1);
                        
                        console.log(data)
                        })  
      
          .catch((err) => {
            console.log('There was a failure calling deleteFlowsDatatableRow');
            console.error(err);
          });
      }
    },
  },


}
</script>

<style lang="scss">
  h1 {
    color: var(--v-secondary);
  }
  div.container > div.v-card > button {
    margin-left: 16px;
  }
  td > div > button {
    margin-left: 10px;
    padding-bottom: 2px;
  }
  .v-small-dialog__content .queues .v-card__title {
    padding: 16px 0;
  }
  .v-small-dialog__content .queues .v-data-table {
    margin-bottom: 16px;
  }
  .layout.wrap .queues .v-card__title {
    padding: 16px 0;
  }
  .layout.wrap .queues {
    padding: 0 4px;
  }
  // .v-sheet.v-card:not(.v-sheet--outlined) {
  //   box-shadow: none;
  // }
</style>