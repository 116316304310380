import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

const MY_ICONS = {
  // complete: '...',
  // cancel: '...',
  // close: '...',
  // delete: '...', // delete (e.g. v-chip close)
  // clear: '...',
  // success: '...',
  // info: '...',
  // warning: '...',
  // error: '...',
  prev: 'icon-navigate_before',
  next: 'icon-navigate_next',
  dropdown: 'icon-arrow_drop_down'
  // checkboxOn: '...',
  // checkboxOff: '...',
  // checkboxIndeterminate: '...',
  // delimiter: '...', // for carousel
  // sort: '...',
  // expand: '...',
  // menu: '...',
  // subgroup: '...',
  // dropdown: '...',
  // radioOn: '...',
  // radioOff: '...',
  // edit: '...',
  // ratingEmpty: '...',
  // ratingFull: '...',
  // ratingHalf: '...',
  // loading: '...',
  // first: '...',
  // last: '...',
  // unfold: '...',
  // file: '...',
}

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
        light: {
          primary: '#ff1e2d',
          secondary: '#66182a',
          accent: '#8c9eff',
          error: '#b71c1c',
          info: '#dddddd',
          disabled: '#0000ff'
        },
    },
  },
  icons: {
    values: MY_ICONS,
  },
});
