<template>
  <v-card>
    <v-card-title>
      Data table: 
      <v-overflow-btn
        :items="tables"
        label="Table"
        hide-details
        v-model="table"
        v-on:change="loadDataTable"
        editable
      ></v-overflow-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-if="entities"
        v-model="search"
        append-icon="icon-search"
        label="Search"
        class="search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-dialog v-if="entities" v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2" v-on="on">Add new row</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Add row to '{{ getTableName() }}'</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md4 v-for="(header,key) in headers" :key="key">
                <v-text-field v-if="header.type == 'string'" v-model="newItem[header.value]" :label="header.text"></v-text-field>
                <v-text-field v-if="header.type == 'integer'" v-model.number="newItem[header.value]" type="integer" :label="header.text"></v-text-field>
                <v-checkbox v-if="header.type == 'boolean'" v-model="newItem[header.value]" :label="`${header.text}: ${newItem[header.value]}`"></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" text @click="closeNew">Cancel</v-btn>
          <v-btn color="primary" dark class="mb-2" text @click="saveNew">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="entities"
      class="elevation-1"
      :search="search"
      v-if="entities"
    >
      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr v-for="(item,idx) in items" :key="idx">
            <td v-for="(header,key) in headers" :key="key">
              <div v-if="header.value == 'key'">
                {{item[header.value]}}
              </div>
              <div v-if="header.value != 'key' && header.type == 'string'">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  @save="save(item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                > {{item[header.value]}}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item[header.value]"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-if="header.type == 'integer'">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  @save="save(item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                > {{item[header.value]}}
                  <template v-slot:input>
                    <v-text-field
                      v-model.number="item[header.value]"
                      type="number"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-if="header.type == 'number'">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  @save="save(item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                > {{item[header.value]}}
                  <template v-slot:input>
                    <v-text-field
                      v-model.number="item[header.value]"
                      type="number"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-if="header.type == 'boolean'">
                <v-edit-dialog
                  :return-value.sync="item[header.value]"
                  large
                  @save="save(item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                > {{item[header.value]}}
                  <template v-slot:input>
                    <v-checkbox
                      v-model="item[header.value]"
                      :on-icon="'icon-check_box'"
                      :off-icon="'icon-check_box_outline_blank'"
                      :label="`${header.text}: ${item[header.value]}`"
                    ></v-checkbox>
                  </template>
                </v-edit-dialog>
              </div>
              <div v-if="header.type == 'actions'">
                <v-icon
                  small
                  @click="deleteDataTableRow(item)"
                >
                  icon-delete
                </v-icon>
              
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="icon-warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'DataTables',

  mounted: function () {
    this.ArchitectApi = new window.platformClient.ArchitectApi();
    this.getDataTables();
  },

  methods: {
    getDataTables: function () {
      let opts = { 
        //'expand': "expand_example", // String | Expand instructions for the result
        //'pageNumber': 1, // Number | Page number
        'pageSize': 500, // Number | Page size
        // 'sortBy': "id", // String | Sort by
        // 'sortOrder': "ascending", // String | Sort order
        // 'divisionId': ["divisionId_example"], // [String] | division ID(s)
        // 'name': "name_example" // String | Name to filter by
      };

      this.ArchitectApi.getFlowsDatatables(opts)
        .then(data => {
          var dts = [];

          data.entities.forEach(dt => {
          dts.push({ text: dt.name, value: dt.id });
          });

          this.tables = dts;

          // Load default table
          this.table = dts.filter(t => {
            return t.text === "Thomas_WebTest";
          })[0].value;
          this.loadDataTable(this.table);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        })
    },
    getDataTable: function (tableId, params) {
      let opts = { 
        // 'pageNumber': 1, // Number | Page number
        'pageSize': 500, // Number | Page size
        'showbrief': false // Boolean | If true returns just the key value of the row
      };

      this.ArchitectApi.getFlowsDatatableRows(tableId, (params ? params : opts))
        .then(data => {
          this.getDataTableSchema(tableId);
          this.entities = data.entities;
          let pages= data.pageCount;
            if  (pages>1){
             
              for (let i = 2; i <= pages; i++) {
            opts.pageNumber=i;
            this.ArchitectApi.getFlowsDatatableRows(tableId,  opts)

            .then(data => {

              this.entities =  this.entities.concat(data.entities);            
           
            })

              }

            }
         
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        })
    },
    getDataTableSchema: function (tableId) {
      let opts = { 
        'expand': "schema" // String | Expand instructions for the result
      };
      this.ArchitectApi.getFlowsDatatablesDivisionview(tableId, opts)
        .then((data) => {
          this.defaultItem = {};
          var headers = []
          
          for (var key of Object.keys(data.schema.properties)) {
            var header = { text: data.schema.properties[key].title, value: key, type: data.schema.properties[key].type, order: data.schema.properties[key].displayOrder }

            // if (data.schema.properties[key].title.charAt(0) == '-') {
            //   header['align'] = ' d-none';
            // }

            headers.push(header)
            this.defaultItem[header.value] = header.type == 'boolean' ? false : header.type == 'string' ? '' : 0;
            this.resetNew();
          }

          headers.sort(
            function(a, b) {          
                return a.order > b.order ? 1 : -1;
            });
          headers.push({ text: 'Actions', type: 'actions', sortable: false })
          this.headers = headers
        })
        .catch((err) => {
          console.log('There was a failure calling getFlowsDatatablesDivisionview');
          console.error(err);
        });
    },
    loadDataTable: function (table) {
      this.getDataTable(table, { pageSize: 500, showbrief: false });
    },
    addDataTableRow: function (row) {
      this.ArchitectApi.postFlowsDatatableRows(this.table, row)
        .catch((err) => {
          console.log('There was a failure calling postFlowsDatatableRows');
          console.error(err);
        });
    },
    updateDataTableRow: function (row) {
      this.ArchitectApi.putFlowsDatatableRow(this.table, row.key, {body: row})
        .catch((err) => {
          console.log('There was a failure calling putFlowsDatatableRow');
          console.error(err);
        });
    },
    deleteDataTableRow: function (row) {
      const index = this.entities.indexOf(row)
      if (confirm('Are you sure you want to delete this row?')){
        this.ArchitectApi.deleteFlowsDatatableRow(this.table, row.key)
          .then(() => {
            this.entities.splice(index, 1);
          })
          .catch((err) => {
            console.log('There was a failure calling deleteFlowsDatatableRow');
            console.error(err);
          });
      }
    },
    getTableName: function () {
      if (this.tables && this.table){
        return this.tables.filter(t => { return t.value === this.table })[0].text;
      } else {
        return '';
      }
    },
    getUrlParams: function (params) {
      return Object.keys(params).map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
      }).join('&');
    },
    save (item) {
      console.log('Dialog save')
      console.log(item)
      this.updateDataTableRow(item);
      // this.snack = true
      // this.snackColor = 'success'
      // this.snackText = 'Data saved'
    },
    cancel () {
      console.log('Dialog cancel')
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open () {
      console.log('Dialog open')
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close () {
      console.log('Dialog closed')
    },
    saveNew () {
      this.entities.push(this.newItem)
      this.addDataTableRow(this.newItem)
      this.closeNew()
    },
    closeNew () {
      this.dialog = false
      setTimeout(() => {
        this.resetNew()
      }, 300)
    },
    resetNew () {
      this.newItem = Object.assign({}, this.defaultItem)
    }
  },

  data () {
    return {
      ArchitectApi: {},
      table: '',
      tables: [],
      search: '',
      headers: [],
      entities: null,
      defaultItem: {},
      newItem: {},
      dialog: false
    }
  }
}
</script>

<style lang="scss">
  h1 {
    color: var(--v-secondary);
  }
  div.container > div.v-card > button {
    margin-left: 16px;
  }
  td > div > button {
    margin-left: 10px;
    padding-bottom: 2px;
  }
  // .v-sheet.v-card:not(.v-sheet--outlined) {
  //   box-shadow: none;
  // }
</style>