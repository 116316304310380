<template>
  <v-container>
    <div class="view-selection">
      <h1 color="secondary">Administrations tool</h1>
      <v-btn color="primary" dark class="mb-2" v-on:click="navigate('datatables')">Data tables</v-btn>
      <v-btn color="primary" dark class="mb-2" v-on:click="navigate('functions')">Function selectors</v-btn>
      <ShowAdminToolsToggle v-if="this.$root.$data.sharedState.isAdmin && this.$root.$data.sharedState.isDeveloper" v-on="$listeners" :showAdminTools="true"></ShowAdminToolsToggle>
    </div>
    <div class="clear-both"></div>
    <component v-bind:is="component" ref="childComponent"></component>
  </v-container>
</template>

<script>
import DataTables from './DataTables'
import FunctionsEditor from './FunctionsEditor';
import ShowAdminToolsToggle from './ShowAdminToolsToggle'

export default {
  name: 'Admin',

  components: {
    'datatables': DataTables,
    'functions': FunctionsEditor,
    ShowAdminToolsToggle
  },

  methods: {
    navigate: function (name) {
      this.component = name
    }
  },

  data () {
    return {
      component: 'datatables'
    }
  }
}
</script>

<style lang="scss">
  h1 {
    color: var(--v-secondary);
  }

  div.view-selection > h1 {
    padding-bottom: 9px;
  }

  div.view-selection > h1, div.view-selection > button {
    float: left;
  }

  div.view-selection > div.v-input--checkbox {
    float: right;
  }

  div.view-selection div.v-input--checkbox > div.v-input__control > div.v-input__slot > div.v-input--selection-controls__input > .v-icon {
    font-size: 44px;
  }

  div.view-selection div.v-input--checkbox > div.v-input__control > div.v-input__slot > label {
    padding-left: 6px;
    padding-top: 1px;
  }

  div.view-selection > div.v-input--checkbox {
    margin-left: 18px;
    margin-top: 7px;
  }

  div.view-selection > button {
    margin-top: 5px;
    margin-left: 16px;
  }
  
  div.clear-both {
    clear: both; 
  }

  div.container > div.v-card > button {
    margin-left: 16px;
  }

  td > div > button {
    margin-left: 10px;
    padding-bottom: 2px;
  }
  
  .v-card__title .v-overflow-btn .v-select__slot {
    height: 32px;
  }
  
  .v-card__title > .v-input {
    margin-top: 0px;
  }
  
  .v-card .v-card__title > .v-input.v-text-field.v-text-field--single-line {
    padding-top: 0px;
  }
  
  .v-card .v-card__title > .v-input.search.v-text-field.v-text-field--single-line {
    padding-top: 2px;
  }
  
  .v-application .v-card .v-card__title button.mb-2, .v-small-dialog__content .v-card__title button.mb-2 {
    margin-bottom: 0px !important;
  }
  // .v-sheet.v-card:not(.v-sheet--outlined) {
  //   box-shadow: none;
  // }
</style>