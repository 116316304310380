<template>
  <div class="skills">
    <v-card-title v-if="skilllist">
      <v-overflow-btn
        :items="skilllist"
        label="Select skill"
        hide-details
        v-model="skill"
        editable
      ></v-overflow-btn>
      <v-btn color="primary" dark class="mb-2" v-on:click="addSkillToFunction(skill)">Add skill</v-btn>
    </v-card-title>
    <v-data-table
      :headers="skillheaders"
      :items="func.Skill"
      class="elevation-1"
      v-if="func.Skill"
    >
      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr v-for="(skill,idx) in items" :key="idx">
            <td v-for="(header,key) in headers" :key="key">
              <div v-if="header.text != 'Actions'">
                {{skill[header.value]}}
              </div>
              <div v-if="header.text == 'Actions'">
                <v-icon
                  small
                  @click="removeSkillFromFunction(skill)"
                >
                  icon-delete
                </v-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'SkillEditor',

  props: {
    table: String,
    func: {
      type: Object,
      default: function () {
        return {
          "Division": "div",
          "Skill": "[\"\"]",
          "key": "key",
          "Name": "name" 
        }
      }
    },
    skills: {
      type: Array,
      default: function () {
        return []; 
      }
    },
    editingItem: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },

  mounted: function () {
    this.ArchitectApi = new window.platformClient.ArchitectApi();
  },

  methods: {
    sortFunctionSkills: function (func) {
      if (func.Skill.length > 0) {
        func.Skill = func.Skill.sort(
          function(a, b) {          
              return a.name > b.name ? 1 : -1;
          });

        func.SkillText = func.Skill.map(q => q.name).join(", ");
      } else {
        func.SkillText = "";
      }
    },
    addSkillToFunction: function (skillId) {
      if(!Array.isArray(this.func.Skill)) {
        this.func.Skill=[];
      }
      if (!this.func.Skill.some(q => q.id === skillId)) {
        var skill = this.skills.find(q => q.value === skillId)
        this.func.Skill.push({ name: skill.text, id: skill.value })
        this.sortFunctionSkills(this.func)
        this.updateDataTableRow(this.func)
      }
    },
    removeSkillFromFunction: function (skill) {
      var idx = this.func.Skill.findIndex(q => q.id === skill.id)
      if (idx > -1) {
        this.func.Skill.splice(idx, 1)
        this.sortFunctionSkills(this.func)
        this.updateDataTableRow(this.func)
      }
    },
    updateDataTableRow: function (row) {
      if (this.editingItem) {
        var body = { ...row };
        delete body.SkillText;
        delete body.isMember;
        var skills = [...new Set(body.Skill.map(q => q.id))];
        body.Skill =  skills.join(";");
        var queues = [...new Set(body.Queues.map(q => q.id))];
        body.Queues =  queues.join(";");
        var authToken = window.client.authData.accessToken;
        body.Auth ={
                            "Token":authToken
                            } ;

                            fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/EditFunction", {
                      method: 'PUT',
                      body:JSON.stringify(body), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      })
         
          .catch((err) => {
            console.log('There was a failure calling putFlowsDatatableRow');
            console.error(err);
          });
      }
    },
  },

  data () {
    return {
      ArchitectApi: {},
      skill: {},
      skilllist: this.skills,
      skillheaders: [{text: "Name", value: "name"}, {text: "Actions", value: "id"}],
      dialog: false
    }
  }
}
</script>

<style lang="scss">
  .v-small-dialog__content .queues .v-card__title {
    padding: 16px 0;
  }
  .v-small-dialog__content .queues .v-data-table {
    margin-bottom: 16px;
  }
</style>