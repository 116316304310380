import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        welcomeMsg: 'Welcome to the Agent Admin Web App!'
    },
    'da': {
        welcomeMsg: 'Velkommen til Agent Admin Web App\'en!'
    }
};

export default new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'da', // set fallback locale
    messages, // set locale messages
});