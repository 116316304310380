<template>
  <v-checkbox v-model="sat" label="Show admin tools" :on-icon="'icon-toggle_on'" :off-icon="'icon-toggle_off'" @click="showAdminToolsToggle(sat)"></v-checkbox>
</template>

<script>
export default {
  name: 'ShowAdminToolsToggle',

  props: {
    showAdminTools: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },

  methods: {
    showAdminToolsToggle: function (show) {
      this.$emit('event_showAdminTools', show)
    }
  },

  data () {
    return {
      sat: this.showAdminTools
    }
  }
}
</script>