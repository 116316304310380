<template>
    <v-container style="background-color:#ffffff !important">
      <v-row>
        <v-col
          cols="6">
          <v-text-field
            v-model="contact"
            label="Contact"
          ></v-text-field>
        </v-col>
        <v-col
          cols="6">
          <v-text-field
            v-model="phone"
            label="Phone"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col
          cols="6">
          <v-select v-model="group" 
          :items="this.$root.$data.sharedState.MemberOfGroups" 
          label="Group"/>
        </v-col>
        <v-col
        cols="6"
      >
      <v-checkbox
      v-model="default_"
      :on-icon="'icon-check_box'"
      :off-icon="'icon-check_box_outline_blank'"
      :label="`Default: ${default_}`"
    ></v-checkbox>
      </v-col>
    </v-row>

    <v-row>
        <v-col
          cols="12">
          <v-select v-model="default_queue" 
          :items="this.default_queue_list" 
          item-text="Name"
          item-value="Name"
          label="Queues"/>
        </v-col>
    </v-row>


      <v-row>
          <v-col
              cols="6"
             
          >
          <v-btn color="primary"
              class="ma-2"
              elevation="1"
              dark 
              block
              style="margin: 0px !important"
              @click="addEntry">
              save
          </v-btn>
           </v-col>
          <v-col
              cols="6"
             
          >
       <v-btn color="primary"
           class="ma-2"
           elevation="1"
           dark 
           block
           style="margin: 0px !important"
           @click="closeModal">
           cancel
       </v-btn>
      </v-col>
       </v-row>
    </v-container>
</template>




<script>

import { v4 as uuidv4 } from 'uuid';
export default {
name: "AddPhonebookEntry",

data() { 
   return {
       phone:'',
       contact:'',
       group:'',
       architectApi: {},
       default_:false,
       default_queue:'',
       default_queue_list: []
   }
},

created() {
   this.architectApi = new window.platformClient.ArchitectApi();

this.getQueues();
   
},

methods:{ getQueues:  function(){
     console.log("getting queues");
    var authToken = window.client.authData.accessToken;
           var opts = {     
             
                            "Token":authToken
                      
                                                 
           };
            fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/GetQueuesByUser/" + window.userData.id, {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                          this.default_queue_list=data;
                       return data;
                                         
                        })  
               .catch(err => {
                console.log("There was a failure calling getQueues");
                  console.error(err);
                  return null;
               });
   },
   closeModal : function(){
       this.clearDataFields();
       this.$emit('close');
   },
   addEntry : async function (){
       if(!this.contact.length > 0){
           alert("A contact name needs to be present in order to save this entry");
           return;
       }
       if(!this.phone.length > 0){
           alert("A phonenumber needs to be present in order to save this entry");
           return;
       }
       if(!this.group.length > 0){
           alert("A group needs to be selected in order to save this entry");
           return;
       }
       if(!this.default_queue.length > 0){
           alert("A Queue needs to be selected in order to save this entry");
           return;
       }
       let filledCurrentWhitelables = false;
       for(const p of this.$root.$data.sharedState.whiteLableIds){

           filledCurrentWhitelables = await this.addRowToDatatable(p);
           console.log(`successfull added row '${filledCurrentWhitelables}''`);
           if(filledCurrentWhitelables){
               this.$emit('saved');
               break;
           }
       }

       if(!filledCurrentWhitelables){
        /*   let addedNewWhitelableAndDataEntry = await this.addNewWhitelable();
           if(addedNewWhitelableAndDataEntry){
               this.$emit('saved');
           }else{
               console.error("Couldn't add new datatable 'whitelable' and new entry");
           }*/
       }

       this.closeModal();
   },
   clearDataFields: function(){
       this.phone='';
       this.contact='';
       this.group='';
       this.default_=false;
   },
   addRowToDatatable: function(){ //whitelable
       return new Promise((resolve,reject) => {
                 
           var datatableId =  uuidv4();
           console.log(datatableId);
           var authToken = window.client.authData.accessToken;
           var dataTableRow = {
               "Key": datatableId,
               "Contact":this.contact,
               "Phone":this.phone,
               "Default":this.default_.toString(),
               "Group":this.group,
               "Default_Queue":this.default_queue,
               "Auth": {
                            "Token":authToken
                            } 
           };

             fetch(process.env.VUE_APP_API_BASE_URI + "/WhiteLabel/AddPhone", {
                      method: 'POST',
                      body:JSON.stringify(dataTableRow), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                        console.log(`postFlowsDatatableRows success! data: ${JSON.stringify(data, null, 2)}`);
                        resolve(true);
                        })  
               .catch(err => {
                   console.log("There was a failure calling postFlowsDatatableRows");
                   if(err.status == 400){
                       if(err.body.message.includes("No additional DataTable rows are allowed")){
                           resolve(false);
                       }
                   }else
                   {
                       reject(err);
                   }
               });
       });
   },
   addNewWhitelable: async function(){
       var self = this;
       console.log("Now enterying new whitelable add")
       while(this.$root.$data.sharedState.whitelableSchema == undefined){
           await new Promise(() => setTimeout(() => 50));
       }
       return new Promise((resolve,reject) => {
           var ps = Object.assign({},this.$root.$data.sharedState.whitelableSchema)
           //Delete the elements that is not used for creating new whitelable, we should not give this to a create datatable statement
           delete ps.id;
           delete ps.selfUri;
           delete ps.datatableId;
           //Set name to be the next whitelable
           ps.name = "Whitelable"+(this.$root.$data.sharedState.whiteLableIds.length + 1);
           console.log("Ready to add whitelable table with the following schema");
           console.log(ps);
           
           //Create it using the API
           this.architectApi.postFlowsDatatables(ps)
               .then((data) => {
                   console.log("Find new id of whitelable");
                   self.$root.$data.sharedState.whiteLableIds.push({"id":data.id,"name":data.name});
                   self.addRowToDatatable() // {"id":data.id,"name":data.name}
                       .then(res => resolve(res))
                       .catch(err => reject(err));
               })
               .catch(err => {
                   reject(err);
               });
       });
   }
   
}
}


</script>


<style scoped>

</style>