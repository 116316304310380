<template>
  <v-card>
    <v-card-title>
      Phone book
      <!-- Edit checkbox -->
      <v-checkbox v-if="this.$root.$data.sharedState.isPhonebookEditor || this.$root.$data.sharedState.isAdmin"
        style="margin: 0 10px 0 15px;"
        v-model="edit"
        color="primary"
        :label="'Edit'"
        :on-icon="'icon-toggle_on'"
        :off-icon="'icon-toggle_off'"
        hide-details
        @click="editTrigged"
      ></v-checkbox>
      <div v-if="edit">
        <div class="text-center">
          <v-btn
            color="primary"
            class="ma-2"
            elevation="5"
            dark 
            @click="showAddModal">
            Add phonebook entry
          </v-btn>
        </div>
      </div>
      <v-spacer></v-spacer>
       <!-- Search field -->
      <v-text-field
        v-model="phoneSearch"
        append-icon="icon-search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <!--The Phonebook datatable-->
    <v-data-table
      :headers="phoneBookHeaders"
      :items="filteredPhoneBookEntries"
      class="elevation-1"
      :search="phoneSearch"
    >

      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr v-for="(item,idx) in items" :key="idx">
            <td v-for="(header,key) in headers" :key="key">
              <!-- Phone div -->
              <div v-if="header.value == 'Phone'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-if="!edit">
                      <a v-on:click="showMakeCallModal(item)" v-bind="attrs" v-on="on">{{item[header.value]}}</a>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            :disabled="externalParticipantId == '' &&  conversationId == ''"
                            @click="consultCall(item[header.value])"
                            v-bind="attrs"
                            v-on="on"
                          >
                            icon-group_add
                          </v-icon>
                        </template>
                        <span>Start consultation</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            :disabled="userParticipantId == '' && conversationId == ''"
                            @click="transferCall(item[header.value])"
                            v-bind="attrs"
                            v-on="on"
                          >
                            icon-sync_alt
                          </v-icon>
                        </template>
                        <span>Transfer call</span>
                      </v-tooltip>
                    </div>

                    <div v-else>
                      <v-edit-dialog
                        v-model="tempItemName"
                        large
                        @save="save(item, header.value, tempItemName)"
                        @open="open(item, header.value)"
                        @cancel="cancel"
                        @close="close"
                    > {{item[header.value]}}
                      <template v-slot:input>
                        <v-text-field
                          v-model="tempItemName"
                          label="Edit"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                    </div>
                  </template>
                  <span>Call number</span>
                </v-tooltip>
              </div>
              
              <!-- Group div -->
              <div v-if="header.value == 'Group'">
                <div v-if="edit">
                  <v-edit-dialog
                      v-model="tempItemName"
                      large
                      @save="save(item, header.value, tempItemName)"
                      @open="open(item, header.value)"
                      @cancel="cancel"
                      @close="close"
                    > {{item[header.value]}}
                    <template v-slot:input>
                      <v-select
                        v-model="tempItemName"
                        :items="MemberOfGroups"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </div>
                <div v-else>
                  {{item[header.value]}}
                </div>
              </div>

              <!-- action div-->
              <div v-if="header.value == 'Action' && edit">
                <v-icon
                  small
                  @click="deleteRow(item)"
                >
                  icon-delete
                </v-icon>
              </div>

              <!-- The rest of the headers -->
              <div v-if="header.value != 'Phone' && header.value != 'Group' && header.value != 'Action'">
                <div v-if="edit">
                  <v-edit-dialog
                      v-model="tempItemName"
                      large
                      @save="save(item, header.value, tempItemName)"
                      @open="open(item, header.value)"
                      @cancel="cancel"
                      @close="close"
                    > {{item[header.value]}}
                      <template v-slot:input>
                        <v-text-field
                          v-model="tempItemName"
                          label="Edit"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                </div>
                <div v-else>
                  {{item[header.value]}}
                </div>
              </div>
            </td>
             
          </tr>
        </tbody>
        
      </template>

      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="icon-warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </template>
    </v-data-table>

    <v-dialog 
      v-model="isAddModalActive"
      width="500"
      >
      <AddPhonebookEntry v-show="isAddModalActive" @close="closeAddModal" @saved="loadData"/>
    </v-dialog>
  </v-card>
 
</template>

<script>

import AddPhonebookEntry from "./AddPhonebookEntry.vue";
// import MakeCall from './MakeCall.vue';

export default {
    name: "Phonebook",
    components: { 
      AddPhonebookEntry//,
      // MakeCall
    },
    created() {
        this.NotificationsApi = new window.platformClient.NotificationsApi();
        this.ArchitectApi = new window.platformClient.ArchitectApi();
        this.ConversationsApi = new window.platformClient.ConversationsApi();
        this.GroupsApi = new window.platformClient.GroupsApi();
        this.loadData();
        this.getActiveCalls();
        
    },
    computed: {
      filteredPhoneBookEntries() {
        if (this.phoneBookEntries.length > 0) {
          if (this.suspendFiltering) {
            return this.phoneBookEntries;
          }

          return this.phoneBookEntries.filter(d =>
              
              d.Contact.toLowerCase().includes(this.phoneSearch.toLowerCase())
              || d.Phone.toLowerCase().includes(this.phoneSearch.toLowerCase())
              || d.Group.toLowerCase().includes(this.phoneSearch.toLowerCase())
              || d.Note.toLowerCase().includes(this.phoneSearch.toLowerCase())
              
          );
        } else {
          return this.phoneBookEntries;
        }
      },
    },
    methods: {
        loadData: async function () {
            if (this.$root.$data.sharedState.phoneBookIds != undefined) {
                this.headers = [];
                this.phoneBookEntries = [];
               // this.$root.$data.sharedState.phoneBookIds.map(phonebook => this.combineDataTables(phonebook.id, 500, 1));
               this.combineDataTables();
              }
        },
        showMakeCallModal: function(contact){
          console.log(contact);
          this.$emit('event_showMakeCallModal', contact);
        },
        closeMakeCallModal: function(){
          this.isMakeCallModalActive=false;
        },
        closeAddModal: function(){
          this.isAddModalActive =false;
        },
        showAddModal: function(){
          this.isAddModalActive = true;
        },
        editTrigged: function (){
          if(this.edit){
            this.phoneBookHeaders.push({"text":"Actions","value":"Action","type":"actions","sortable":false});
          }else{
            console.log("Hit this slice!");
            const indx = this.phoneBookHeaders.findIndex(i => i.type === 'actions');
            this.phoneBookHeaders.splice(indx, indx >= 0 ? 1 : 0);
          }
        },
        editNumber: function (cur_num, type) {
            console.log(cur_num.tableId);
            console.log(type);
        },
        deleteRow: function (row){
          
          var authToken = window.client.authData.accessToken;
           
              let opts = {
                  "Token" : authToken
                };
         
            fetch(process.env.VUE_APP_API_BASE_URI + "/FalckTools/DeletePhone/" + row.key, {
                      method: 'DELETE',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) 
            .then(() => {
              console.log("deleteFlowsDatatableRow returned successfully.");
              this.loadData();
            })
            .catch((err) => {
              console.log("There was a failure calling deleteFlowsDatatableRow");
              console.error(err);
            });
        },
        setConversationInfo: function (conversationInfo) {
            console.log(`setConversationInfo: ${JSON.stringify(conversationInfo, null, 2)}`);
            this.conversationId = conversationInfo.conversationId;
            this.externalParticipantId = conversationInfo.externalParticipantId;
            this.userParticipantId = conversationInfo.userParticipantId;
        },
        getDataTableSchema: function (tableId) {
          tableId="9e232f06-cf10-4654-950c-81643392f24b";
            let opts = {
                "Expand": "schema" ,// String | Expand instructions for the result
                "Id":tableId
            };
            var self = this;
            fetch(process.env.VUE_APP_API_BASE_URI + "/Datatables/Schema", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      })
                .then((data) => {
                var headers = [];
                //Save the phonebook schema (is consistent accros phonebooks)
                self.$root.$data.sharedState.phoneBookSchema = data;
                //Force refresh of schema after update
                self.$root.$data.sharedState.refreshRequired = true;
                

                for (var key of Object.keys(data.schema.properties)) {
                    if (key != "key") {
                        headers.push({ text: data.schema.properties[key].title, value: key, type: data.schema.properties[key].type });
                    }
                }
                
                this.combineHeaders(headers);
            })
                .catch((err) => {
                console.log("There was a failure calling getFlowsDatatablesDivisionview");
                console.error(err);
            });
        },
        combineDataTables: function () {
           var  tableId="9e232f06-cf10-4654-950c-81643392f24b";
            if (tableId) {

              var authToken = window.client.authData.accessToken;
              let opts = {
                  "Token" : authToken
                };
                var self = this;
                //Set local MemberOfGroups to the shared, in order to use this as selector for phonebook edit
                this.MemberOfGroups = self.$root.$data.sharedState.MemberOfGroups;
                console.log("member of groups")
                console.log(this.MemberOfGroups)
                try {
                    self.getDataTableSchema(tableId);
                    
                    // skip loading the schema if it's already cached, unless specifically flagged to refresh it 
                    if(self.$root.$data.sharedState.refreshRequired === true || this.$root.$data.sharedState.phonebookEntries.length==0){
                      self.$root.$data.sharedState.refreshRequired = false;

                    fetch(process.env.VUE_APP_API_BASE_URI + "/FalckTools/Phones", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                        self.phoneBookEntries =
                           data.map(x => this.addTableId(x, tableId)).filter(ent => self.$root.$data.sharedState.MemberOfGroups.includes(ent.Group))
                            .sort(function (a, b) {
                            return a.Contact > b.Contact ? 1 : -1;
                        });

                        this.$root.$data.sharedState.phonebookEntries =  self.phoneBookEntries;

                       
                    })
                        .catch((err) => {
                        console.log("There was a failure calling getFlowsDatatableRows");
                        console.error(err);
                    });

                  } else {
                    self.phoneBookEntries = this.$root.$data.sharedState.phonebookEntries; 
                  }
                }
                catch (err) {
                    console.log(err);
                }
            }
        },
        addTableId: function (json, id) {
            json.tableId = id;
            return json;
        },
        combineHeaders: function (newHeaders) {
            var c = this.phoneBookHeaders.concat(newHeaders);
            this.phoneBookHeaders = c.filter((item, pos) => c.map(i => i.value).indexOf(item.value) === pos);
        },
        getActiveCalls: function () {
            var self = this;
            this.ConversationsApi.getConversationsCalls()
                .then(data => {
                if (data.entities.length == 1) {
                    var upi = data.entities[0].participants.filter(p => {
                        return p.user && p.user.id === self.$root.$data.sharedState.user.id;
                    })[0].id;
                    var epi = data.entities[0].participants.filter(p => {
                        return p.id !== upi && p.purpose=="customer";
                    }).sort(function(a,b){
  // Turn your strings into dates, and then subtract them
  // to get a value that is either negative, positive, or zero.
  return new Date(a.connectedTime)- new Date(b.connectedTime);
})[0].id;
                    var info = {
                        "conversationId": data.entities[0].id,
                        "externalParticipantId": epi,
                        "userParticipantId": upi
                    };
                    this.setConversationInfo(info);
                }
            })
                .catch((err) => {
                console.log("There was a failure calling getConversationsCalls");
                console.error(err);
            });
        },
        makeCall: function (phone) {
            console.log(phone);
            let body = { "phoneNumber": "+4540144590", "callerId": "70102030" }; // Object | Call request
            this.ConversationsApi.postConversationsCalls(body)
                .catch((err) => {
                console.log("There was a failure calling postConversationsCalls");
                console.error(err);
            });
        },
        consultCall: function (phone) {
            let body = {
                "speakTo": "",
                "destination": {
                    "address": phone,
                    "name": "",
                    "userId": "",
                    "queueId": ""
                }
            }; // Object | Destination address & initial speak to
            this.ConversationsApi.postConversationsCallParticipantConsult(this.conversationId, this.externalParticipantId, body)
                .catch((err) => {
                console.log("There was a failure calling postConversationsCallParticipantConsult");
                console.error(err);
            });
        },
        transferCall: function (phone) {
            let body = {
             
                "address": phone
                
            }; // Object | Transfer request
            this.ConversationsApi.postConversationParticipantReplace(this.conversationId, this.userParticipantId, body)
                .catch((err) => {
                console.log("There was a failure calling postConversationParticipantReplace");
                console.error(err);
            });
        },
        updateDataTableRow: function (row) {
            if (this.edit) {
              var authToken = window.client.authData.accessToken;

              var body = { ...row,
                  "Auth": {
                  "Token":authToken
                  } 
           } ;

                var tableId = body.tableId;
                if (tableId) {
                    // Remove the id from the body, since this is not part of the actual table
                    delete body.tableId;
                    // Now update the row, with the new key as well
                    fetch(process.env.VUE_APP_API_BASE_URI + "/FalckTools/EditPhone", {
                      method: 'PUT',
                      body:JSON.stringify(body), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                        
                        console.log(data)
                        }) 
                        .catch((err) => {
                        console.log("There was a failure calling putFlowsDatatableRow");
                        console.error(err);
                    });
                }
            }
        },
        save(item, type, newValue) {
          console.log("Dialog save");
          item[type] = newValue;
          if (this.currentlyEditingItem) {
            this.currentlyEditingItem.name = this.tempItemName;
          }
          this.close(); // Reset the editing state
          this.tempItemName = '';
            this.updateDataTableRow(item);
        },
        cancel() {
            console.log("Dialog cancel");
            this.close(); // Reset the editing state
            this.tempItemName = '';
        },
        close() {
            this.currentlyEditingItem = null;
            this.suspendFiltering = false;
            console.log("Dialog closed");
        },
        open(item, type) {
          this.tempItemName = item[type];
          this.currentlyEditingItem = item;
          this.suspendFiltering = true;
        },
    },
    data() {
        return {
            ArchitectApi: {},
            ConversationsApi: {},
            NotificationsApi: {},
            GroupsApi: {},
            connection: null,
            edit: false,
            connectUri: "",
            channelId: "",
            phoneBookHeaders: [],
            phoneBookEntries: [],
            MemberOfGroups: [],
            phoneSearch: "",
            conversationId: "",
            userParticipantId: "",
            externalParticipantId: "",
            isAddModalActive: false,
            isMakeCallModalActive : false,
            contact : "",
            tempItemName: '',
            suspendFiltering: false,
            phone:""
        };
    }
}
</script>

<style lang="scss">
  h1 {
    color: var(--v-secondary);
  }

  div.view-selection > h1, div.view-selection > button {
    float: left;
  }

  div.view-selection > button {
    margin-top: 5px;
    margin-left: 16px;
  }
  
  div.clear-both {
    clear: both; 
  }
  
  td > div > button {
    margin-left: 10px;
    padding-bottom: 2px;
  }
</style>