<template>
  <div class="queues">
    <v-card-title v-if="queuelist">
      <v-overflow-btn
        :items="queuelist"
        label="Select queue"
        hide-details
        v-model="queue"
        editable
      ></v-overflow-btn>
      <v-btn color="primary" dark class="mb-2" v-on:click="addQueueToFunction(queue)">Add queue</v-btn>
    </v-card-title>
    <v-data-table
      :headers="queueheaders"
      :items="func.Queues"
      class="elevation-1"
      v-if="func.Queues"
    >
      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr v-for="(queue,idx) in items" :key="idx">
            <td v-for="(header,key) in headers" :key="key">
              <div v-if="header.text != 'Actions'">
                {{queue[header.value]}}
              </div>
              <div v-if="header.text == 'Actions'">
                <v-icon
                  small
                  @click="removeQueueFromFunction(queue)"
                >
                  icon-delete
                </v-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'QueueEditor',

  props: {
    table: String,
    func: {
      type: Object,
      default: function () {
        return {
          "Division": "div",
          "Queues": "[\"\"]",
          "key": "key",
          "Name": "name" 
        }
      }
    },
    queues: {
      type: Array,
      default: function () {
        return []; 
      }
    },
    editingItem: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },

  mounted: function () {
    this.ArchitectApi = new window.platformClient.ArchitectApi();
  },

  methods: {
    sortFunctionQueues: function (func) {
      if (func.Queues.length > 0) {
        func.Queues = func.Queues.sort(
          function(a, b) {          
              return a.name > b.name ? 1 : -1;
          });

        func.QueueText = func.Queues.map(q => q.name).join(", ");
      } else {
        func.QueueText = "";
      }
    },
    addQueueToFunction: function (queueId) {
      if (!this.func.Queues.some(q => q.id === queueId)) {
        var queue = this.queues.find(q => q.value === queueId)
        this.func.Queues.push({ name: queue.text, id: queue.value })
        this.sortFunctionQueues(this.func)
        this.updateDataTableRow(this.func)
      }
    },
    removeQueueFromFunction: function (queue) {
      var idx = this.func.Queues.findIndex(q => q.id === queue.id)
      if (idx > -1) {
        this.func.Queues.splice(idx, 1)
        this.sortFunctionQueues(this.func)
        this.updateDataTableRow(this.func)
      }
    },
    updateDataTableRow: function (row) {
      if (this.editingItem) {
        var body = { ...row };
        delete body.QueueText;
        delete body.isMember;
        var queues = [...new Set(body.Queues.map(q => q.id))];
        body.Queues =  queues.join(";");
        var skills = [...new Set(body.Skill.map(q => q.id))];
        body.Skill =  skills.join(";");
        var authToken = window.client.authData.accessToken;
        body.Auth ={
                            "Token":authToken
                            } ;

                            fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/EditFunction", {
                      method: 'PUT',
                      body:JSON.stringify(body), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      })
         
          .catch((err) => {
            console.log('There was a failure calling putFlowsDatatableRow');
            console.error(err);
          });
      }
    },
  },

  data () {
    return {
      ArchitectApi: {},
      queue: {},
      queuelist: this.queues,
      queueheaders: [{text: "Name", value: "name"}, {text: "Actions", value: "id"}],
      dialog: false
    }
  }
}
</script>

<style lang="scss">
  .v-small-dialog__content .queues .v-card__title {
    padding: 16px 0;
  }
  .v-small-dialog__content .queues .v-data-table {
    margin-bottom: 16px;
  }
</style>