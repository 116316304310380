<template>
  <v-app :style="cssProps">
    <v-main>
      <Agent v-if="this.$root.$data.sharedState.isDeveloper && !showAdminTools" v-on:event_showAdminTools="eventShowAdminTools"/>
      <Admin v-if="this.$root.$data.sharedState.isAdmin && showAdminTools" v-on:event_showAdminTools="eventShowAdminTools"/>
      <!-- <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          {{ $t('welcomeMsg') }}
        </h1>

        <p class="subheading font-weight-regular">
          For help and collaboration with other Vuetify developers,
          <br>please join our online
          <a
            href="https://community.vuetifyjs.com"
            target="_blank"
          >Discord Community</a>
        </p>

        <v-overflow-btn
          :items="languages"
          label="Languages"
          v-model="language"
          v-on:change="changeLocale"
        ></v-overflow-btn>
      </v-col> -->
    </v-main>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import Admin from './components/Admin.vue'
import Agent from './components/Agent.vue'

// import i18n from './plugins/i18n'

import settings from 'app-settings'

export default {
  name: 'App',

  components: {
    //HelloWorld,
    Admin,
    Agent
  },

  created() {
    
    // Get the client from window (it is registered in index.html header)
    const client = window.platformClient.ApiClient.instance
    
    // Set the environment
    client.setEnvironment(window.platformClient.PureCloudRegionHosts.eu_central_1);

    // Login the client
    client.loginImplicitGrant('e1a379ef-61ae-44a0-94c8-d6a22ebd5bb9', settings.redirectUrl, { state: this.state })
      .then(() => {
        this.gCC = client;
        window.client=client;
        // expand the information to include Groups
        let opts = { 
          "expand": ["groups"], // [String] | Which fields, if any, to expand.
          //"integrationPresenceSource": "integrationPresenceSource_example" // String | Get your presence for a given integration. This parameter will only be used when presence is provided as an expand.
        };

        // Do authenticated things
        this.usersApi = new window.platformClient.UsersApi()
        this.usersApi.getUsersMe(opts)
          .then((data) => {
            this.$root.$data.sharedState.user = data
            this.getRolePermissions(data.id);
            window.userData=data;
            this.getQueues();
          })
          .catch(function (error) {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          })
        
        // Get all the list of groups, ids and the associated members
        this.groupApi = new window.platformClient.GroupsApi();
        this.getListOfGroups(500,1);

        //Flag to indicate when we are done looping through groups
        this.$root.$data.sharedState.LoadedAllJoinedGroups = false;

        // Find the groups 'me' belong to
        this.getNameOfJoinedGroups();

       
       
      // this.getSkills();

      })
      .catch((err) => {
        // Handle failure response
        console.log(err);
      });

      // Init some root data
      this.$root.$data.sharedState.whitelableEntries = []
      this.$root.$data.sharedState.phonebookEntries = []
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    cssProps () {
      var themeColors = {}
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
      })
      return themeColors
   }
  },

  methods: { getQueues:  function(){
    console.log("getting queues");
    var authToken = window.client.authData.accessToken;
           var opts = {     
             
                            "Token":authToken
                      
                                                 
           };
            fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/GetQueuesByUser/"+ window.userData.id, {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                        window.default_queue_list=data;
                       return data;
                                         
                        })  
               .catch(err => {
                console.log("There was a failure calling getQueues");
                  console.error(err);
                  return null;
               });
   },getSkills:  function(){
     console.log("getting skills");
    var authToken = window.client.authData.accessToken;
           var opts = {     
             
                            "Token":authToken
                         
           };
            fetch(process.env.VUE_APP_API_BASE_URI + "/FunctionsSelector/GetSkills", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      }).then(data => {
                          window.default_skill_list=data;
                          console.log("Skill_List: "  + data);
                        
                       return data;
                                         
                        })  
               .catch(err => {
                console.log("There was a failure calling getSkills");
                  console.error(err);
                  return null;
               });
   },
    getRolePermissions: function (userId) {
      this.usersApi.getUserRoles(userId)
        .then(data => {
          console.log(data.roles);
          this.$root.$data.sharedState.isAdmin = data.roles.findIndex(x => x.name.toUpperCase() === 'admin website'.toUpperCase()) > -1;
          this.$root.$data.sharedState.isDeveloper = data.roles.findIndex(x => x.name.toUpperCase() === 'user website'.toUpperCase()) > -1;
          this.$root.$data.sharedState.isWhitelableEditor = data.roles.findIndex(x => x.name.toUpperCase() === 'whitelable editor website'.toUpperCase()) > -1;
          this.$root.$data.sharedState.isPhonebookEditor = data.roles.findIndex(x => x.name.toUpperCase() === 'phonebook editor website'.toUpperCase()) > -1;

          if (this.$root.$data.sharedState.isAdmin && !this.$root.$data.sharedState.isDeveloper) {
            this.showAdminTools = true;
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        })
    },
    eventShowAdminTools: function (toggle) {
      this.showAdminTools = toggle
    },
    getNameOfJoinedGroups: async function() {
      while (!this.loadedAllGroups){
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      while (this.$root.$data.sharedState.user == undefined){
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      var me = this.$root.$data.sharedState.user;
      
      this.$root.$data.sharedState.MemberOfGroups = [];
      me?.groups.forEach(ga => {
        var group = this.availableGroups.find(g => g.id == ga.id);
        if(group != undefined){
          this.$root.$data.sharedState.MemberOfGroups =  this.$root.$data.sharedState.MemberOfGroups.concat(group.name);
        }
      });
      this.$root.$data.sharedState.LoadedAllJoinedGroups = true;
    },
    
    getListOfGroups: function(pageSize,pageNumber){
      //TODO: make this a while
      let opts = { 
      "pageSize": pageSize, // Number | Page size
      "pageNumber": pageNumber, // Number | Page number
      //"id": ["id_example"], // [String] | id
      //"jabberId": ["jabberId_example"], // [String] | A list of jabberIds to fetch by bulk (cannot be used with the id parameter)
      "sortOrder": "ASC" // String | Ascending or descending sort order
      };  

      try {
        this.availableGroups = [];
        this.groupApi.getGroups(opts)
        .then((data) => {
            data.entities.forEach((group) => 
              {
                this.availableGroups = this.availableGroups.concat({"id":group.id, "name":group.name});
              });
            if(data.nextUri != undefined){
              this.getListOfGroups(pageSize,pageNumber+1);
            }else{
              this.loadedAllGroups = true;
            }
        })
        .catch((err) => {
                console.log('There was a failure calling getListOfGroups');
                console.error(err);
        });
      }
      catch (err){
        console.log(err);
      }
    }
  },
  data () {
    return {
      gCC: {},
      usersApi: {},
      groupApi: {},
      availableGroups: [],
      loadedAllGroups: false,
      showAdminTools: false
      // language: { text: 'English', value: 'en' },
      // languages: [
      //   { text: 'English', value: 'en' },
      //   { text: 'Dansk', value: 'da' }
      // ]
    }
  }
};
</script>
<style scoped>
@import './assets/style.css';

h1 {
  color: var(--v-secondary);
}
@media (min-width: 960px) {
  .container {
    max-width: initial;
  }
}
</style>