<template>
  <v-card>
    <v-btn v-if="contact.Contact != ''"
      absolute
      top
      right
      color="primary"
      class="ma-2"
      elevation="0"
      @click="Cancel"
      style="min-width:20px !important;width:20px;height:20px;padding:0 !important"
      dark><v-icon>icon-clear</v-icon></v-btn>
    <v-card-title :style=" contact ? 'text-align: center' : ''">Make Call{{contact.Contact != '' ? ` to '${contact.Contact}'` : ''}}</v-card-title>
    <v-row :style="contact.Contact != '' ? 'margin: 0;': ''">
      <v-col :cols="contact.Contact != '' ? '12' : '5'" class="no-top-bottom-padding">
        <v-row :style="contact.Contact != '' ? 'margin-bottom: 12px;' : ''">
          <v-col :cols="contact.Contact != '' ? '12' : '4'" class="no-top-bottom-padding">
            <v-subheader :style=" contact.Contact != '' ? 'text-align: center' : ''">Call number</v-subheader>
          </v-col>
          <v-col :cols="contact.Contact != '' ? '12' : '8'" class="no-top-bottom-padding">
            <v-text-field
              v-model="contact.Phone"
              hide-details
              dense
              :class="contact.Contact != '' ? 'input-left-right-margin' : ''"
              :readonly="contact.Contact != '' ? true: false"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="contact.Contact != '' ? '12' : '5'" class="no-top-bottom-padding">
        <v-row>
          <v-col :cols="contact.Contact != '' ? '12' : '4'" class="no-top-bottom-padding">
            <v-subheader :style=" contact.Contact != '' ? 'text-align: center' : ''">On behalf of</v-subheader>
          </v-col>
          <v-col :cols="contact.Contact != '' ? '12' : '8'" class="no-top-bottom-padding">
            <v-combobox
              :class="contact.Contact != '' ? 'input-left-right-margin' : ''"
              dense
              hide-details
              v-model="selectedBehalf"
              :items="this.onBehalfOf"
              outlined
            ></v-combobox>
          </v-col>
        </v-row>
        <!-- <v-row style="margin-top: 0;">
          <v-col :cols="contact.Contact != '' ? '12' : '4'" class="no-top-bottom-padding">
            <v-subheader :style=" contact.Contact != '' ? 'text-align: center' : ''">Caller Id</v-subheader>
          </v-col>
          <v-col :cols="contact.Contact != '' ? '12' : '8'" class="no-top-bottom-padding">
            <v-text-field
              :class="contact.Contact != '' ? 'input-left-right-margin' : ''"
              filled
              :value="selectedBehalf ? selectedBehalf.Phone : ''"
              readonly
              dense
            />
          </v-col>
        </v-row> -->
      </v-col>
      <v-col :cols="contact.Contact != '' ? '12' : '2'" class="no-top-bottom-padding" :style="contact.Contact != '' ? 'text-align: center' : ''">
        <v-btn
          color="primary"
          class="ma-2"
          elevation="5"
          :style="contact.Contact != '' ? 'margin-top: 30px !important;' : 'margin-top: -10px !important;'"
          @click="makeCall"
          :disabled="!selectedBehalf"
          dark>Make call<v-icon>icon-call</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "MakeCall",
  props: {
    contact: {
      type: Object,
      default: function () {
        return {
          Contact: '',
          Phone: ''
        }
      }
    },
    onBehalfOf: { type: Array } 
    // func: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       "Division": "div",
    //       "Queues": "[\"\"]",
    //       "key": "key",
    //       "Name": "name" 
    //     }
    //   }
    // },
  },
  created() {
    this.ConversationsApi = new window.platformClient.ConversationsApi();

    if (this.onBehalfOf) {
      var defaultWles = this.onBehalfOf.filter((wle) => wle.Default);
      if (defaultWles.length == 1) {
        this.selectedBehalf = defaultWles[0];
      }
    }
    
  },
  methods: {
    setContact (contact) {
      console.log('setContact');
      console.log(contact);
      this.contact = contact;
    },
    makeCall: async function () {
      var self = this;
      var authToken = window.client.authData.accessToken;
      let bd = { 
        
            "phoneNumber": this.contact.Phone, 
                  "callerId": this.selectedBehalf.Phone,
                  "callFromQueueId":   window.default_queue_list.find(y => y.Name ===this.selectedBehalf["Default_Queue"]).Id,
                  "Auth": {
                    "Token":authToken
      }
                  }; // Object | Call request
                  console.log(this.selectedBehalf);
      var convId = await new Promise ((res,rej) =>{
     // self.ConversationsApi.postConversationsCalls(body)
     fetch(process.env.VUE_APP_API_BASE_URI + "/WhiteLabel/MakeCall", {
      method: 'POST',
      body:JSON.stringify(bd), // string or object
      headers: {
        
        'Content-Type': 'application/json'
      }
  }).then((response) => {
          if (response.ok) {
            console.log("Response: "  + response.body);
            return response.json();
          }
          throw new Error('Something went wrong');
        })
        .then(data => {
          console.log("ConvId: " + data.id)
           res(data.id);
        })
        .catch((err) => {
          console.log("There was a failure calling postConversationsCalls" 
          +  err);
          rej(err);
        });
      });
      this.$emit('event_callMade');
      if(convId !== undefined){
        await self.updateAttributeDataForCall(convId);
      }
      this.clearData();
    },
    updateAttributeDataForCall : async function(convId){
      //slow this down a second, such that conversation is ready in Genesys Cloud
      await new Promise(resolve => setTimeout(resolve, 1000));
      var self = this;
      var particpantId = await self.getParticpantIdFromUser(convId);
      let body = {
        "attributes": {
          "On Behalf of":self.selectedBehalf.Contact,
          "OBo caller id":self.selectedBehalf.Phone
        }
      };
      // Update the attributes on a conversation participant.
      self.ConversationsApi.patchConversationParticipantAttributes(convId, particpantId, body)
        .then(() => {
          console.log(`Succesfully assigned attribute data to user on conversation '${convId}', with the following body: ${JSON.stringify(body, null, 2)}`);
        })
        .catch((err) => {
          console.log("There was a failure calling patchConversationParticipantAttributes");
          console.error(err);
        });
    },
    getParticpantIdFromUser : function(convId){
      // Get a conversation by id
      var self = this;
      return new Promise((resolve,reject) => {
        self.ConversationsApi.getConversation(convId)
          .then((data) => {
            //Find the particpant id from purpose user
            var participantId = data.participants.filter(p => p.purpose.toLowerCase() === "agent")[0].id;
            if(participantId === undefined){
              reject(`Couldn't find particpant id with purpose 'user' from conversation '${convId}'`);
            }else{
              console.log(`participant id from getParticpantIdFromUser '${participantId}'`);
              resolve(participantId);
            }
            
          })
          .catch((err) => {
            console.log("There was a failure calling getConversation");
            console.error(err);
            reject(err);
          });
      });
    },

    Cancel : function(){
      this.$emit('event_canceled');
      this.clearData();
    },
    clearData : function(){
      this.phoneInput = '';
      this.selectedBehalf = undefined;
      // this.selectedBehalfPhone = '';
    }
  },
  watch: {
    onBehalfOf: function (wles) {
      var defaultWles = wles.filter((wle) => wle.Default);
      if (defaultWles.length == 1) {
        this.selectedBehalf = defaultWles[0];
      }
    }
  },
  data() {
      return {
        ConversationsApi: {},
        // contact: { Contact: '', Phone: ''},
        phoneInput: this.phone,
        selectedBehalf: undefined,
        // selectedBehalfPhone: '',
      };
  }
}
</script>

<style lang="scss">
  .no-top-bottom-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .v-text-field.input-left-right-margin {
    margin-left: 16px;
    margin-right: 16px;
  }
  .no-top-bottom-padding:last-of-type > button.v-btn {
    margin-bottom: 20px !important;
  }
</style>