<template>
      <v-container style="background-color:#ffffff !important">
        <v-row>
          <v-col
            cols="12"
            
          >
            <v-text-field
              v-model="contact"
              label="Contact"
            ></v-text-field>
          </v-col>
          </v-row>
          <v-row>
          <v-col
            cols="12"
            
          >
            <v-text-field
              v-model="phone"
              label="Phone"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            
          >
            <v-select v-model="group" :items="this.$root.$data.sharedState.MemberOfGroups" label="Group"/>
          </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="6"
                
            >
            <v-btn color="primary"
                class="ma-2"
                elevation="1"
                dark 
                block
                style="margin: 0px !important"
                @click="addEntry">
                save
            </v-btn>
             </v-col>
            <v-col
                cols="6"
                
            >
         <v-btn color="primary"
             class="ma-2"
             elevation="1"
             dark 
             block
             style="margin: 0px !important"
             @click="closeModal">
             cancel
         </v-btn>
        </v-col>
         </v-row>
      </v-container>
  </template>




<script>

import { v4 as uuidv4 } from 'uuid';
export default {
 name: "AddPhonebookEntry",

 data() { 
     return {
         phone:'',
         contact:'',
         group:'',
         architectApi: {}
     }
 },

 created() {
     this.architectApi = new window.platformClient.ArchitectApi();
 },

 methods:{
     closeModal : function(){
         this.clearDataFields();
         this.$emit('close');
     },
     addEntry : async function (){
         if(!this.contact.length > 0){
             alert("A contact name needs to be present in order to save this entry");
             return;
         }
         if(!this.phone.length > 0){
             alert("A phonenumber needs to be present in order to save this entry");
             return;
         }
         if(!this.group.length > 0){
             alert("A group needs to be selected in order to save this entry");
             return;
         }
         let filledCurrentPhonebooks = false;
         for(const p of this.$root.$data.sharedState.phoneBookIds){

             filledCurrentPhonebooks = await this.addRowToDatatable(p);
             console.log(`successfull added row '${filledCurrentPhonebooks}''`);
             if(filledCurrentPhonebooks){
                 this.$emit('saved');
                 break;
             }
         }

         if(!filledCurrentPhonebooks){
          /*   let addedNewPhonebookAndDataEntry = await this.addNewPhonebook();
             if(addedNewPhonebookAndDataEntry){
                 this.$emit('saved');
             }else{
                 console.error("Couldn't add new datatable 'phonebook' and new entry");
             }*/
         }

         this.closeModal();
     },
     clearDataFields: function(){
         this.phone='';
         this.contact='';
         this.group='';
     },
     addRowToDatatable: function(){ //phonebook
         return new Promise((resolve,reject) => {
           
            var authToken = window.client.authData.accessToken;
            var datatableId =  uuidv4();
             console.log(datatableId);
             var dataTableRow = {
                 "Key": datatableId,
                 "Contact":this.contact,
                 "Phone":this.phone,
                 "Group":this.group,
                 "Auth": {
                            "Token":authToken
                            } 
             }
              fetch(process.env.VUE_APP_API_BASE_URI + "/FalckTools/AddPhone", {
                      method: 'POST',
                      body:JSON.stringify(dataTableRow), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) 
                 .then((data) => {
                     console.log(`postFlowsDatatableRows success! data: ${JSON.stringify(data, null, 2)}`);
                     resolve(true);
                 })
                 .catch(err => {
                     console.log("There was a failure calling postFlowsDatatableRows");
                     if(err.status == 400){
                         if(err.body.message.includes("No additional DataTable rows are allowed")){
                             resolve(false);
                         }
                     }else
                     {
                         reject(err);
                     }
                 });
         });
     },
     addNewPhonebook: async function(){
         var self = this;
         console.log("Now enterying new phonebook add")
         while(this.$root.$data.sharedState.phoneBookSchema == undefined){
             await new Promise(() => setTimeout(() => 50));
         }
         return new Promise((resolve,reject) => {
             var ps = Object.assign({},this.$root.$data.sharedState.phoneBookSchema)
             //Delete the elements that is not used for creating new phonebook, we should not give this to a create datatable statement
             delete ps.id;
             delete ps.selfUri;
             delete ps.datatableId;
             //Set name to be the next phonebook
             ps.name = "Phonebook"+(this.$root.$data.sharedState.phoneBookIds.length + 1);
             console.log("Ready to add phone book with following schema");
             console.log(ps);
             
             //Create it using the API
             this.architectApi.postFlowsDatatables(ps)
                 .then((data) => {
                     console.log("Find new id of phonebook");
                     self.$root.$data.sharedState.phoneBookIds.push({"id":data.id,"name":data.name});
                     self.addRowToDatatable({"id":data.id,"name":data.name})
                         .then(res => resolve(res))
                         .catch(err => reject(err));
                 })
                 .catch(err => {
                     reject(err);
                 });
         });
     }
     
 }
}
 

</script>


<style scoped>

 </style>