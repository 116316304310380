<template>
  <v-container>
    <v-dialog
      v-model="isMakeCallModalActive"
      width="500"
    >
      <MakeCall
        :contact="contact"
        :onBehalfOf="this.whitelableEntries"
        @event_callMade="closeMakeCallModal"
        @event_canceled="closeMakeCallModal"
        ref="makeCallDialog"/>
      </v-dialog>
    <div class="view-section">
      <MakeCall
        :onBehalfOf="this.whitelableEntries" />
    </div>
    <div class="clear-both"></div>
    <div class="view-selection">
      <h1 color="secondary">User tools</h1>
      <v-btn color="primary" dark class="mb-2" v-on:click="navigate('phonebook')">Phone book</v-btn>
      <v-btn color="primary" dark class="mb-2" v-on:click="navigate('functions')">Function selector</v-btn>
      <v-btn v-if="this.$root.$data.sharedState.isWhitelableEditor || this.$root.$data.sharedState.isAdmin" color="primary" dark class="mb-2" v-on:click="navigate('whiteLabel')">White label</v-btn>
      <v-checkbox v-model="userIsLastInLine" label="Last user in line" :on-icon="'icon-toggle_on'" :off-icon="'icon-toggle_off'" @click="setLastUserInLine(userIsLastInLine)"></v-checkbox>
      <ShowAdminToolsToggle v-if="this.$root.$data.sharedState.isAdmin && this.$root.$data.sharedState.isDeveloper" v-on="$listeners" :showAdminTools="false"></ShowAdminToolsToggle>
    </div>
    <div class="clear-both"></div>
    <component v-bind:is="component" ref="childComponent" v-on:event_showMakeCallModal="showMakeCallModal" v-on:event_whitelableModified="GetListOfAvailableWhiteLabels"></component>
  </v-container>
</template>

<script>
import PhoneBook from './PhoneBook'
import Functions from './Functions'
import WhiteLabel from './WhiteLabel'
import ShowAdminToolsToggle from './ShowAdminToolsToggle'
import MakeCall from './MakeCall'

export default {
  name: 'Agent',

  components: {
    'phonebook': PhoneBook,
    'functions': Functions,
    'whiteLabel': WhiteLabel,
    ShowAdminToolsToggle,
    MakeCall
  },

  mounted: function () {
    this.NotificationsApi = new window.platformClient.NotificationsApi();
    this.ArchitectApi = new window.platformClient.ArchitectApi();
    this.RoutingApi = new window.platformClient.RoutingApi();
    this.UsersApi = new window.platformClient.UsersApi();
    this.setUpWebSocketConnection();
    this.getPhoneBookFunctionsForDivisionsPermitted();
    this.getLastUserInLineStatus();
  //  this.getDataTables(500,1,[],[],[]);
    this.GetListOfAvailableWhiteLabels();
  
  },

  methods: {
    setUpWebSocketConnection: function () {
      var self = this; // assign context to self variable
      this.NotificationsApi.postNotificationsChannels()
        .then(data => {
          console.log(`postNotificationsChannels success! data: ${JSON.stringify(data, null, 2)}`);
          this.connectUri = data.connectUri
          this.channelId = data.id

          this.connection = new WebSocket(this.connectUri)

          this.connection.onmessage = function(event) {
            self.messageReceived(event)
          }

          this.connection.onopen = function(event) {
            console.log(event)
            console.log("Successfully connected to the echo websocket server...")
          }

          var topics = [
            {"id": "v2.users." + this.$root.$data.sharedState.user.id + ".conversations.calls"},
            // {"id": "v2.routing.queues." + this.eCallQueueId + ".conversations.calls"}
          ]

          this.NotificationsApi.putNotificationsChannelSubscriptions(this.channelId, topics)
            .then((data) => {
              console.log(`putNotificationsChannelSubscriptions success! data: ${JSON.stringify(data, null, 2)}`);
            })
            .catch((err) => {
              console.log('There was a failure calling putNotificationsChannelSubscriptions');
              console.error(err);
            });
        })
        .catch((err) => {
          console.log('There was a failure calling postNotificationsChannels');
          console.error(err);
        });
    },
    messageReceived: function (event) {
      var eventData = JSON.parse(event.data)

      if (eventData.topicName.startsWith("v2.users.")) {
    
        let user = eventData.eventBody.participants.filter(p => {
                        return p.user && p.user.id === this.$root.$data.sharedState.user.id;
                    })[0];

        if (user.state === "connected" &&  this.info.userParticipantId == '') {
       
             this.info.userParticipantId = user.id;
                  
            var customers = eventData.eventBody.participants.filter(p => {
                        return p.purpose==="customer" && p.id !== this.info.userParticipantId 
                    }).sort(function(a,b){
  // Turn your strings into dates, and then subtract them
  // to get a value that is either negative, positive, or zero.
  return new Date(a.connectedTime)- new Date(b.connectedTime);
});

if (customers.length>0){
  this.info.externalParticipantId = customers[0].id;
}
          
        
        this.info.conversationId = eventData.eventBody.id
          this.$refs.childComponent.setConversationInfo(this.info);
        } else {
          
        
        let user = eventData.eventBody.participants.filter(p => {
                        return p.user && p.user.id === this.$root.$data.sharedState.user.id;
                    })[0];

      if(user.state === 'disconnected' || user.state === 'terminated' ) {
          this.info.userParticipantId = ''
          this.info.externalParticipantId = ''
          this.info.conversationId = ''
       
          this.$refs.childComponent.setConversationInfo(this.info);
         } 
        }
      // } else if (eventData.topicName.startsWith("v2.routing.queues.")) {
      //   var customer = eventData.eventBody.participants.find(function(p) {
      //     return p.purpose === 'customer';
      //   })

      //   switch (customer.state) {
      //     case 'connected':
      //       // Check if an agent is handling call
      //       var agent = eventData.eventBody.participants.find(function(p) {
      //         return p.purpose === 'agent';
      //       })
            
      //       if (agent) {
      //         if (agent.state === 'alerting' || agent.state === 'connected')
      //         // Remove VIP call from list of queued VIP calls
      //         this.vipCallNotify(eventData.eventBody.id, false, eventData)
      //         break;
      //       }
            
      //       var acd = eventData.eventBody.participants.find(function(p) {
      //         return p.purpose === 'acd';
      //       })
            
      //       if (acd.state === 'offering') {
      //         // Add VIP call to list of queued VIP calls
      //         this.vipCallNotify(eventData.eventBody.id, true, eventData)
      //       }
      //       break;
      //     case 'disconnected':
      //       // Remove VIP call from list of queued VIP calls
      //       this.vipCallNotify(eventData.eventBody.id, false, eventData)
      //       break;
      //   }
      }
    },
    GetListOfAvailableWhiteLabels: async function(){
      while (!this.$root.$data.sharedState.whiteLableIds){
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      while (!this.$root.$data.sharedState.LoadedAllJoinedGroups){
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      console.log("Getting list of whitelabels")
  
      this.whitelableEntries = [];
      
        for(var i=0; i<this.$root.$data.sharedState.whiteLableIds.length; i++){
          var pageNumber = 1;
          var gotAllData = false;
          do {
            var authToken = window.client.authData.accessToken;
            let opts = {
                  "Token" : authToken
                };
              var self = this;
              try {
                  var tableId ="a3a257ca-7356-4787-8774-ee5825f304d0";
                  console.log(`Reading all whitelabes from ${tableId}, with options`);
                  console.log(opts);
                  gotAllData = await new Promise(resolved => {
                  var loadedAllData = false;
                  fetch(process.env.VUE_APP_API_BASE_URI + "/WhiteLabel/phones", {
                      method: 'POST',
                      body:JSON.stringify(opts), // string or object
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      })
                      .then(data => {
                        self.whitelableEntries = self.whitelableEntries
                            .concat(data.map(x => self.addTableId(x, tableId)).filter(ent => self.$root.$data.sharedState.MemberOfGroups.includes(ent.Group)))
                            .sort(function (a, b) {
                            return a.Contact > b.Contact ? 1 : -1;
                        });
                        if (data.pageCount > pageNumber) {
                          //  pageNumber++;
                        }
                        else {
                          loadedAllData = true;
                        }
                      resolved(loadedAllData);
                      })
                      .catch((err) => {
                      console.log("There was a failure calling getFlowsDatatableRows");
                      console.error(err);
                    });
                });
              }
              catch (err) {
                  console.log(err);
              }
            
          }while(!gotAllData);
        }
        
        this.whitelableEntries.forEach(wle => {
          wle['text'] = `${wle.Default.toLowerCase()==='true' ? '* ' : ''}${wle.Contact} (${wle.Phone})`;
        });

        let asteriskEntries =     this.whitelableEntries.filter(entry => entry.Default.toLowerCase() === 'true');
        let nonAsteriskEntries =     this.whitelableEntries.filter(entry => entry.Default.toLowerCase() === 'false');
     
        asteriskEntries = asteriskEntries.sort(function(x,y) {
          return y.Default - x.Default;
        });

        nonAsteriskEntries = nonAsteriskEntries.sort(function(x,y) {
          return y.Default - x.Default;
        });
        

        //Sort this array such that The default is first, in alphabetical.
        //Hereafter the non default in alphabetical
        this.whitelableEntries = asteriskEntries.concat(nonAsteriskEntries);

        window.whitelableEntries= this.whitelableEntries ;
    },
    addTableId: function (json, id) {
        json.tableId = id;
        return json;
    },
    getDataTables: function (pageSize,pageNumber,listPhoneBooks,listWhitelable,listFunctions) {
      
      const phonebookReg = /Phonebook\d+/i;
      const whitelableReg = /Whitelable\d+/i;
      const functionReg = /Functions.*/i;
      fetch(process.env.VUE_APP_API_BASE_URI + "/Datatables/Tables", {
                      method: 'POST',
                   
                      headers: {                        
                        'Content-Type': 'application/json'
                      }
                    }) .then((response) => {
                        if (response.ok) {
                          console.log("Response: "  + response.body);
                          return response.json();
                        }
                        throw new Error('Something went wrong');
                      })
        .then(data => {
          data.forEach(dt => {
              if (phonebookReg.test(dt.name)) {
                listPhoneBooks.push({"name":dt.name, "id":dt.id});
              } else if (whitelableReg.test(dt.name)) {
                listWhitelable.push({"name":dt.name, "id":dt.id}) 
              } else if (functionReg.test(dt.name)) {
                listFunctions.push({'name' : dt.name, 'id': dt.id})
              }
          })
          
          if(data.pageCount > pageNumber){
             // this.getDataTables(pageSize,pageNumber+1,listPhoneBooks,listWhitelable,listFunctions);
          }
          else
          {
            this.$root.$data.sharedState.phoneBookIds = listPhoneBooks;
            this.loadedAllPhonebooks = true;
            this.$root.$data.sharedState.whiteLableIds = listWhitelable;
            this.$root.$data.sharedState.functionIds = listFunctions;
            this.$refs.childComponent.loadData();
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        })
    },
  
    // getCurrentPhonebookToFill: async function(){
    //   while (!this.loadedAllPhonebooks){
    //     await new Promise(resolve => setTimeout(resolve, 100));
    //   }
    //   var self = this;
    //   this.$root.$data.sharedState.phoneBookIds.map( 
    //     x => {
    //       let rowCount = self.NumRowsInDatatable(x.id,1,500,0);
    //       console.log(rowCount);
    //       console.log(self.NumRowsInDatatable(x.id,1,500,0));

    //     //x.fillMe = self.NumRowsInDatatable(x.id,1,500,0) <= self.maxRowsInDatatable
    //     });
    
    // },
    // NumRowsInDatatable: function(tabelId,pageNumber,pageSize,rowCount){
    //   var count = rowCount;
    //   let opts = {
    //     'pageNumber':pageNumber,
    //     'pageSize':pageSize
    //   };
    //   var self = this;
    //   this.ArchitectApi.getFlowsDatatableRows(tabelId,opts)
    //   .then(data => {
    //     console.log(count);
    //     count = count + data.total;
    //     if (data.pageCount >= data.pageNumber){
    //       self.NumRowsInDatatable(tabelId,pageNumber+1,pageSize,count);
    //     }else{
    //       return count;
    //     }})
    //   .catch(function (error) {
    //       if (error.response) {
    //         console.log(error.response.data);
    //         console.log(error.response.status);
    //         console.log(error.response.headers);
    //       }
    //   });
    // },

     getPhoneBookFunctionsForDivisionsPermitted: function () {
       fetch(process.env.VUE_APP_API_BASE_URI + "/Datatables/Divisions/" + this.$root.$data.sharedState.user.id, {
         method: 'POST',
             headers: {
           'Content-Type': 'application/json'
         }
       })
       .then((response) => {
         if (response.ok) {
           console.log("Response: "  + response.body);
           return response.json();
         }
         throw new Error('Something went wrong');
       })
       .then(data => {
         var allDivsPermitted = data.AllPermitted;
         var divisions = [...new Set(data.entries.map(d => d.name))];
         this.$root.$data.sharedState.divisionsPermitted = divisions;
         this.$root.$data.sharedState.allDivsPermitted = allDivsPermitted;
         this.getDataTables(500,1,[],[],[]);
       })
       .catch((err) => {
         console.log('There was a failure calling getAuthorizationDivisionspermittedPagedMe');
         console.error(err);
       });
    },
    getLastUserInLineStatus: function () {
      let opts = { 
        'pageSize': 10, // Number | Page size
        'pageNumber': 1, // Number | Page number
        'name': "!AvailableInLine", // String | Filter for results that start with this value
        //'id': ["id_example"] // [String] | id
      };

      var self = this

      this.RoutingApi.getRoutingSkills(opts)
        .then((data) => {
          self.lastUserInLineSkillId = data.entities[0].id
          let userOpts = { 
            'pageSize': 25, // Number | Page size
            'pageNumber': 1, // Number | Page number
            'sortOrder': "ASC" // String | Ascending or descending sort order
          };

          self.UsersApi.getUserRoutingskills(this.$root.$data.sharedState.user.id, userOpts)
            .then((data) => {
              var skillAssigned = data.entities.find(function(skill) {
                return skill.id === self.lastUserInLineSkillId;
              })

              if (!skillAssigned) {
                console.log("SKILL NOT ASSIGNED !")
                let body = {
                  "id": this.lastUserInLineSkillId,
                  "proficiency": 5
                };

                this.UsersApi.postUserRoutingskills(this.$root.$data.sharedState.user.id, body)
                  .then(() => {
                    this.userIsLastInLine = false;
                  })
                  .catch((err) => {
                    this.userIsLastInLine = true;
                    console.log('There was a failure calling postUserRoutingskills');
                    console.error(err);
                });
              } else {
                console.log("SKILL ASSIGNED ! Proficiency: " + skillAssigned.proficiency)
                self.userIsLastInLine = skillAssigned ? skillAssigned.proficiency == 0 : false;
              }
            })
            .catch((err) => {
              console.log('There was a failure calling getUserRoutingskills');
              console.error(err);
            });
        })
        .catch((err) => {
          console.log('There was a failure calling getRoutingSkills');
          console.error(err);
        });
    },
    setLastUserInLine: function (lastInLine) {
      if (this.lastUserInLineSkillId !== '') {
        console.log("lastInLine: " + lastInLine)
        
        let body = {
          "name": "",
          "proficiency": !lastInLine ? 5 : 0,
          "state": ""
        };

        this.UsersApi.putUserRoutingskill(this.$root.$data.sharedState.user.id, this.lastUserInLineSkillId, body)
          .then(() => {
            this.userIsLastInLine = lastInLine;
          })
          .catch ((err) => {
            this.userIsLastInLine = !lastInLine;
            console.log('There was a failure calling putUserRoutingskill');
            console.error(err);
          });
      }
    },
    vipCallNotify: function (conversationId, addCall, event) {
      if (addCall) {
        this.vipCallsList.push(conversationId)
      } else {
        var index = this.vipCallsList.indexOf(conversationId);
        if (index > -1) {
          this.vipCallsList.splice(index, 1);
        }
      }

      this.alertNotification(this.vipCallsList.length > 0, event)
    },
    alertNotification: function (show, event) {
      var icon = document.getElementById("div1");

      if (icon.innerHTML !== "") {
        if (!show) {
          console.log(`--- alertNotification : STOPPING ANIMATION: ${JSON.stringify(event, null, 2)}`)
          clearInterval(this.notificationInterval);
          this.delay(1000).then(() => icon.innerHTML = "");
        }

        return
      }

      console.log(`--- alertNotification : START ANIMATION: ${JSON.stringify(event, null, 2)}`)
      function chargebattery() {
        var a = document.getElementById("div1");
        a.innerHTML = "notifications_active";
        setTimeout(function () {
            a.innerHTML = "notification_important";
          }, 500);
      }
      chargebattery();
      this.notificationInterval = setInterval(chargebattery, 1000);
    },
    delay: function (time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    navigate: function (name) {
      this.component = name
    },
    showAdminToolsToggle: function (show) {
      this.$emit('event_showAdminTools', show)
    },
    showMakeCallModal: function (contact) {
      this.contact = contact;
      this.isMakeCallModalActive = true;
      // console.log('showMakeCallModal');
      // console.log(contact);
      // this.$refs.makeCallDialog.setContact(contact);
    },
    closeMakeCallModal: function (){
      this.isMakeCallModalActive = false;
    },
    startCall: function () {

    }
  },

  data () {
    return {
      component: 'phonebook',
      info: {
        userParticipantId: '',
        externalParticipantId: '',
        conversationId: ''
      },
      ArchitectApi: {},
      RoutingApi: {},
      UsersApi: {},
      NotificationsApi: {},
      notificationInterval: '',
      notificationTimeout: '',
      eCallQueueId: '831d02d9-a479-4dbd-8d88-447f5e8b86bb',
      vipCallsList: [],
      connection: null,
      connectUri: '',
      channelId: '',
      userIsLastInLine: false,
      showAdminTools: false,
      lastUserInLineSkillId: '',
      loadedAllPhonebooks: false,
      whitelableEntries: [],
      maxRowsInDatatable: 5000,
      isMakeCallModalActive: false,
      contact: {},
      phone: ''
    }
  },
}
</script>

<style lang="scss">
  h1 {
    color: var(--v-secondary);
  }

  div.view-selection > h1 {
    padding-bottom: 9px;
  }

  div.view-selection > h1, div.view-selection > button {
    float: left;
  }

  div.view-selection > div.v-input--checkbox {
    float: right;
  }

  div.view-selection div.v-input--checkbox > div.v-input__control > div.v-input__slot > div.v-input--selection-controls__input > .v-icon {
    font-size: 44px;
  }

  div.view-selection div.v-input--checkbox > div.v-input__control > div.v-input__slot > label {
    padding-left: 6px;
    padding-top: 1px;
  }

  div.view-selection > div.v-input--checkbox {
    margin-left: 18px;
    margin-top: 7px;
  }

  div.view-selection > button {
    margin-top: 5px;
    margin-left: 16px;
  }

  div.view-selection > .v-icon {
    float: right;
    color: var(--v-primary);
    font-size: 43px;
    padding-top: 2px;
  }

  div.view-selection:not(:first-child) {
    margin-top: 40px;
  }
  
  div.clear-both {
    clear: both; 
  }

  .v-card .v-card__title > .v-input.search.v-text-field.v-text-field--single-line {
    padding-top: 2px;
  }
  
  td > div > button {
    margin-left: 10px;
    padding-bottom: 2px;
  }

  .view-section .v-card .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background: #cccccc !important;
    color: #ffffff !important;
  }
  // .v-sheet.v-card:not(.v-sheet--outlined) {
  //   box-shadow: none;
  // }
</style>